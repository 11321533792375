import * as GraphqlTypes from './graphql'

// Fix with typescript 3.8 re-exporting
import {
  EnrollMember as ImportedEnrollMember,
  EnrollMemberVariables as ImportedEnrollMemberVariables,
  EnrollDependent as ImportedEnrollDependent,
  EnrollDependentVariables as ImportedEnrollDependentVariables,
  UpdateContactProfile as ImportedUpdateContactProfile,
  UpdateContactProfileVariables as ImportedUpdateContactProfileVariables,
  UpdateContactNotes as ImportedUpdateContactNotes,
  UpdateContactNotesVariables as ImportedUpdateContactNotesVariables
} from '../graphql/secure-operations'

import { MutationFunctionOptions, ExecutionResult, MutationResult } from '@apollo/react-common'

export * from './graphql'

export enum SiteColor {
  BLUE = 'BLUE',
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED'
}

export type Coupon = {
  __typename: 'StripeCoupon'
  id: string
  valid: boolean
  duration: string
  duration_in_months: number | null
  amount_off: number | null
  percent_off: number | null
}

export interface Plan {
  color: 'blue' | 'green' | 'orange'
  name: string
  annualPrice: number
  monthlyPrice?: number
  activeMemberCount: number
  included: { name: string; count: number | null }[]
  discounts: { type: 'global'; discount: number }
  ageRange?: { min?: number; max?: number }
}

export type EnrollmentState = {
  start?: {
    email: string
  }
  details?: {
    firstName: string
    lastName: string
    phone: string
    dobMonth: number
    dobDay: number
    dobYear: number
    perio: boolean
  }
  plan?: {
    id: string
    name: string
    term: GraphqlTypes.PlanTerm
    price: number
  }
  enforce_credit: boolean
  initialStatus?: GraphqlTypes.PlanSubscriptionStatus
}

// IF CHANGED, CHANGE IN MEMBER/PROVIDER TYPES
export type SecureContact = {
  email?: string
  firstName: string
  lastName: string
  phone?: string
  dobMonth: number
  dobDay: number
  dobYear: number
  perio: boolean

  address1?: string
  address2?: string
  city?: string
  state?: State
  zip?: string
}

export type SecureContactNotes = {
  createdAt: number
  body: string
}[]

export type SavedTokenInput = {
  cardType: string;
  expDate: string;
  lastFourDigits: string;
  tokenId: string;
  status?: any;
}

export type EnrollMember = ImportedEnrollMember
export type EnrollNewMemberVariables = ImportedEnrollMemberVariables
export type EnrollDependent = ImportedEnrollDependent
export type EnrollDependentVariables = ImportedEnrollDependentVariables

export type UpdateContactProfile = ImportedUpdateContactProfile
export type UpdateContactProfileVariables = ImportedUpdateContactProfileVariables
export type UpdateContactNotes = ImportedUpdateContactNotes
export type UpdateContactNotesVariables = ImportedUpdateContactNotesVariables

export type UpdateStartEndDate = (
  options?: MutationFunctionOptions<GraphqlTypes.updateStartEndDate, GraphqlTypes.updateStartEndDateVariables> | undefined
) => Promise<ExecutionResult<GraphqlTypes.updateStartEndDate>>
export type UpdateStartEndDateVariables = MutationResult<GraphqlTypes.updateStartEndDate>


export enum State {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UM = 'UM',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY'
}
