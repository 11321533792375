import moment from 'moment'

export const toDollars = (number: number, fractionDigits?: number) => {
  return Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: fractionDigits ?? 0,
    maximumFractionDigits: fractionDigits ?? 0
  }).format(number)
}

export const ageRangeToString = (ageRange: { min?: number | null; max?: number | null } = {}): string => {
  const { min, max } = ageRange

  if (min && max) {
    return `${min} - ${max}`
  } else if (max) {
    return `${max} and Under`
  } else if (min) {
    return `Ages ${min}+`
  } else return 'All Ages'
}

export const prettyPhoneNumber = (number: string) => {
  const cleaned = ('' + number).replace(/\D/g, '')
  const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned)
  return match ? `(${match[1]}) ${match[2]}-${match[3]}` : null
}

export const effectiveDateRange = (timestamp: number) => {
  return `${moment.unix(timestamp).format('M/D/YYYY')} - ${moment
    .unix(timestamp)
    .add(1, 'year')
    .subtract(1, 'day')
    .format('M/D/YYYY')}`
}

export const effectiveDateRangeInDateTime = (startDate: any, endDate: any) => {
  return `${moment.utc(startDate).format('M/D/YYYY')} - ${moment.utc(endDate).format('M/D/YYYY')}`
}