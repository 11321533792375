import * as GraphqlTypes from './graphql'

// Fix with typescript 3.8 re-exporting
import {
  enrollMember as ImportedEnrollNewMember,
  EnrollNewMemberVariables as ImportedEnrollNewMemberVariables,
  UpdateContactProfile as ImportedUpdateContactProfile,
  UpdateContactProfileVariables as ImportedUpdateContactProfileVariables
} from 'graphql/secure-operations'

export type EnrollMember = ImportedEnrollNewMember
export type EnrollNewMemberVariables = ImportedEnrollNewMemberVariables
export type UpdateContactProfile = ImportedUpdateContactProfile
export type UpdateContactProfileVariables = ImportedUpdateContactProfileVariables

export * from './graphql'

export type EnrollmentState = {
  start?: {
    email: string
    password: string
  }
  details?: {
    firstName: string
    lastName: string
    phone: string
    dobMonth: number
    dobDay: number
    dobYear: number
    perio: boolean
  }
  plan?: {
    id: string
    name: string
    term: GraphqlTypes.PlanTerm
    price: number
  }
}

// IF CHANGED, CHANGE IN PROVIDER/LIB TYPES
export type SecureContact = {
  email?: string
  firstName: string
  lastName: string
  phone?: string
  dobMonth: number
  dobDay: number
  dobYear: number
  perio: boolean

  address1?: string
  address2?: string
  city?: string
  state?: GraphqlTypes.State
  zip?: string
}

export type SecureContactNotes = {
  createdAt: number
  body: string
}[]
