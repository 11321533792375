import React from 'react'
import styled from 'styled-components/macro'

import { Pane } from 'evergreen-ui'
import { FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import Icon from './icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { colors } from './utility/theme'

export type Props = {
  index: number
  steps: { icon: IconProp }[]
  circleWidth?: number
  spaceBetween?: number
  iconSize?: FontAwesomeIconProps['size']
}

const ProgressBar = ({ index: progressIndex, steps, circleWidth = 36, spaceBetween = 48, iconSize }: Props) => {
  progressIndex = Math.min(progressIndex, steps.length - 1)

  return (
    <Pane display="flex" alignItems="center" position="relative">
      <ConnectorLine
        color={colors.blue.light}
        step={steps.length - 1}
        circleWidth={circleWidth}
        spaceBetween={spaceBetween}
      />
      <ConnectorLine
        color={colors.blue.base}
        step={progressIndex}
        circleWidth={circleWidth}
        spaceBetween={spaceBetween}
      />

      {steps.map((step, i) => {
        const solid = i <= progressIndex

        return (
          <Step key={i} color={colors.blue.base} solid={solid} circleWidth={circleWidth} spaceBetween={spaceBetween}>
            <Icon icon={step.icon} size={iconSize} color={solid ? 'white' : colors.blue.dark} transition="all 700ms" />
          </Step>
        )
      })}
    </Pane>
  )
}

export default ProgressBar

type StepProps = { color: string; solid: boolean; circleWidth: number; spaceBetween: number }

const Step = styled.div<StepProps>`
  position: relative;
  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;
  width: ${p => p.circleWidth}px;
  height: ${p => p.circleWidth}px;
  border-radius: ${p => p.circleWidth}px;

  background-color: ${p => (p.solid ? colors.blue.base : colors.blue.light)};
  transition: all 700ms;

  &:not(:last-child) {
    margin-right: ${p => p.spaceBetween}px;
  }
`

type ConnectorLineTypes = { step: number; color: string; circleWidth: number; spaceBetween: number }

const ConnectorLine = styled.div<ConnectorLineTypes>`
  position: absolute;
  z-index: 1;
  top: calc(50% - 1px);
  left: ${p => p.circleWidth / 2}px;
  height: 2px;
  width: ${p => p.step * (p.circleWidth + p.spaceBetween)}px;
  background-color: ${p => p.color};
  transition: width 700ms;
`
