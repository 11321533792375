import React from 'react'
import { useFormikContext } from 'formik'

import { Text, Pane, PaneProps } from 'evergreen-ui'

import Icon from './icon'

export interface Props extends PaneProps {
  max?: number
}

const FormError = ({ max, ...props }: PaneProps) => {
  const { touched, errors } = useFormikContext()

  return (
    <Pane minHeight={21} marginTop={8} {...props}>
      {Object.keys(touched)
        .map((key: string, i: number) => {
          return key in errors ? (
            <div key={i}>
              <Icon color="red" icon={['fad', 'times']} marginRight={4} />
              <Text color="red">{(errors as { [key: string]: string })[key]}</Text>
            </div>
          ) : null
        })
        .filter(errorDiv => errorDiv) // removes null values
        .slice(0, max ?? undefined)}
    </Pane>
  )
}

export default FormError
