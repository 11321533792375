import React, { useEffect, useState } from 'react'
import styled from 'styled-components/macro'

import { SideSheet, Pane, Paragraph, Text, Heading, Table, toaster } from 'evergreen-ui'

import CardHeader from '../card-header'
import Button from '../button'

import { colors } from '../utility/theme'
import { useMediaQueryContext } from '../media-query-provider'
import { useMutation, useQuery } from '@apollo/react-hooks'
import * as Types from './../types'
// import Spinner from '../../src/spinner'
//import { ServiceSubscriptions_Receipt_paymentEvent } from './../types'
import moment from 'moment'
import { GET_SERVICE_RECIPT_BY_ID, REFUND_AMOUNT_SERVICE_PAYMENT_EVENT } from './../graphql/_receipt'
// import { isJson } from '../utility/transforms'
import VoidPaymentDialog from '../_dialogs/void-payment-dialog'
import ConfirmDialogPayment from '../_dialogs/confirm-dialog'
import { Spinner } from '..'
import { PaymentStatus } from './../types'

import axios from 'axios'


//import {auth} from 'firebase'

export type Props = {
    isShown: boolean
    setIsShown: (isShown: boolean) => void,
    paymentEventId?: string,
    getAccountToken?: any,
    hostUrl: string,
    tanentType?: string,
    // isAdmin?: boolean,
    // updatePracticeInvoice?: any
}



const PaymentRecipt = ({
    isShown,
    setIsShown,
    paymentEventId,
    getAccountToken,
    hostUrl,
    tanentType,
    // isAdmin = false,
    // updatePracticeInvoice
}: Props) => {
    const [refundDialogShown, setRefundDialogShown] = useState(false)
    let secure: any = "";
    let receiptData: any = {};
    useEffect(() => {
        console.log("paymentEventId", paymentEventId);

    }, [])
    // if (tanentType === "service") {
    const { data: serviceReciptData } = useQuery<Types.ServiceReceiptByIdQuery, Types.ServiceReceiptByIdQueryVariables>(GET_SERVICE_RECIPT_BY_ID, {
        context: { secure: false },
        variables: {
            paymentEventId: paymentEventId ? paymentEventId : ''
        },
        skip: !paymentEventId
    });
    receiptData = serviceReciptData?.serviceReceipt;

    secure = {
        firstName: receiptData?.account?.name,
        lastName: ""
    };
    // }
    // else {
    // const { data } = useQuery<Types.MemberReceiptByIdQuery, Types.MemberReceiptByIdQueryVariables>(GET_RECIPT_BY_ID, {
    //     context: { secure: true },
    //     variables: {
    //         paymentEventId: paymentEventId ? paymentEventId : ''
    //     },
    //     skip: !paymentEventId
    // });

    // receiptData = memberReciptData?.memberReceipt;
    // secure = memberReciptData?.memberReceipt?.secure && isJson(memberReciptData?.memberReceipt?.secure) ?
    //     (JSON.parse(memberReciptData?.memberReceipt?.secure) as Types.SecureContact) : null;
    // }
    const downloadReceipt = hostUrl;
    const downloadMemberPDF = () => {
        getAccountToken && getAccountToken().then((token: string) => {

            axios({
                method: 'post',
                url: downloadReceipt + "download/receipt/" + tanentType, //'http://localhost:4000/download/receipt',
                responseType: 'blob',
                headers: { Authorization: `Bearer ${token}` },
                data: receiptData
            }).then((response) => {
                const file = new Blob([response.data], {
                    type: 'application/pdf',
                });

                const fileURL = URL.createObjectURL(file);

                window.open(fileURL);
            }, (error) => {
                console.log(error);
            });
        }).catch((err: any) => {
            console.log(err);
        })
    };
    // const EmailReceipt = () => {
    //     getAccountToken && getAccountToken().then((token: string) => {
    //         axios({
    //             method: 'post',
    //             url: downloadReceipt + "email/receipt/" + tanentType, //'http://localhost:4000/download/receipt',
    //             responseType: 'blob',
    //             headers: { Authorization: `Bearer ${token}` },
    //             data: receiptData
    //         }).then((response) => {
    //             if (response.status = 200) {
    //                 toaster.success(`Email Sent successfully`)
    //             }
    //         }, (error) => {
    //             console.log(error);
    //         });
    //     }).catch((err: any) => {
    //         console.log(err)
    //     })
    // }

    // const VoidPayment = () => {
    //     // ToDo: ask for notes and confirmation.
    //     setShowVoidPaymentDialog(true)
    // }


    const [RefundAmountServicePaymentEvent, RefundAmountServicePaymentEventVariables] = useMutation<Types.RefundAmountServicePaymentEvent, Types.RefundAmountServicePaymentEventVariables>(REFUND_AMOUNT_SERVICE_PAYMENT_EVENT, {
        refetchQueries: ['SERVICE_BILLING_HISTORY']
    })
    const refundAmount = () => {
        RefundAmountServicePaymentEvent({ variables: { id: receiptData.id, paymentStatus: "REFUNDED" } })
    }

    useEffect(() => {
        if (!RefundAmountServicePaymentEventVariables.loading) {
            if (RefundAmountServicePaymentEventVariables.error) {
                toaster.danger(`Unable to refund the amount`)
                setIsShown(false)
            }
            else if (RefundAmountServicePaymentEventVariables?.data?.refundAmountServicePaymentEvent?.paymentStatus === 'REFUNDED') {
                // if (updatePracticeInvoice) {
                //     updatePracticeInvoice(RefundAmountServicePaymentEventVariables?.data?.refundAmountServicePaymentEvent)
                // }
                toaster.success('Payment successfully refunded!')
                setIsShown(false)
            }
        }
    }, [RefundAmountServicePaymentEventVariables])


    const { isMobile } = useMediaQueryContext()
    const [showVoidPaymentDialog, setShowVoidPaymentDialog] = useState(false)

    const receiptDetails = receiptData;
    const dateRangeDesc = (receiptDetails?.dateRangeStart && receiptDetails?.dateRangeEnd) ?
        `${moment.utc(receiptDetails?.dateRangeStart).format('M/D/YYYY')} to ${moment.utc(receiptDetails?.dateRangeEnd).format('M/D/YYYY')}`
        : null

    // const isVoidable = isAdmin && tanentType !== 'service'
    // const isRefundable = isAdmin && tanentType === 'service'


    return (
        <>
            {
                paymentEventId ? <VoidPaymentDialog paymentEventId={paymentEventId} isShown={showVoidPaymentDialog}
                    setIsShown={setShowVoidPaymentDialog}
                    successCB={() => { setIsShown(false) }} // Need to close the receipt sheet.
                /> : <></>
            }
            <ConfirmDialogPayment
                isShown={refundDialogShown}
                setIsShown={setRefundDialogShown}
                confirm={() => refundAmount()}
                body={"Are you sure you want to refund ?"}
                intent="danger"
            />
            <SideSheet
                isShown={isShown}
                onCloseComplete={() => setIsShown(false)}
                width={733}
                className="SideSheet"
                preventBodyScrolling={true}
                //@ts-ignore
                position={isMobile ? 'bottom' : 'right'}
                shouldCloseOnOverlayClick= {false}
            >
                {
                    !receiptData ? (
                        <Pane height={84} paddingY={24}>
                            <Spinner delay={0} />
                        </Pane>
                    ) : (
                        <SheetLayout className='SheetLayout' >
                            <CardHeader gridArea="header">
                                <Heading size={600}>Print Receipt for  {secure?.firstName} {secure?.lastName}</Heading>
                            </CardHeader>

                            <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
                                <Pane margin={24}>
                                    <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                                        Payment Receipt: {receiptData?.invoiceNumber}
                                    </Heading>
                                    <Text marginTop={0} ><b>{receiptData?.practiceName ? receiptData?.practiceName : ''}</b></Text>
                                    <Paragraph marginBottom={24}>
                                        {receiptData?.office?.name}<br />
                                        {receiptData?.office?.address1}, {receiptData?.office?.address2}
                                        {receiptData?.office?.city} {receiptData?.office?.state}
                                        {receiptData?.office?.zip}
                                        <br /> Tel: {receiptData?.office?.phone}
                                    </Paragraph>
                                    <Pane display="flex" width="100%" >
                                        <Pane display="flex" flexDirection="column" width="50%">
                                            <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                                                Billing details
                                            </Heading>
                                            <Pane display="flex" flexDirection="column" marginBottom={24}>
                                                <Text marginBottom={4} overflowWarp="break-word" wordWrap="break-word" >
                                                    <strong>Plan: </strong>
                                                    {receiptDetails?.plan}

                                                </Text>
                                                {
                                                    dateRangeDesc ?
                                                        <Text marginBottom={4}>
                                                            <strong>Period: </strong>
                                                            {dateRangeDesc}
                                                        </Text> : <></>
                                                }
                                                <Text marginTop={0}>
                                                    <strong>Bill to: </strong>
                                                    {secure?.firstName} {secure?.lastName}
                                                </Text>
                                                {secure?.email ?
                                                    <Text marginTop={0}>
                                                        <strong>Email: </strong>
                                                        {secure.email}
                                                    </Text> : <></>
                                                }
                                                { /* Show invoice details */
                                                    receiptDetails?.invoiceNumber ? (
                                                        <>
                                                            <Text marginBottom={4}>
                                                                <strong>Invoice Number: </strong>
                                                                <Text textTransform="uppercase" >{receiptDetails.invoiceNumber}</Text>
                                                            </Text>
                                                            <Text marginBottom={4}>
                                                                <strong>Invoice Date: </strong>
                                                                {receiptDetails.invoiceDate ? moment.utc(receiptDetails.invoiceDate).format('M/D/YYYY') : ''}
                                                            </Text>
                                                        </>
                                                    )
                                                        : (
                                                            <Text marginBottom={4}>
                                                                <strong>No Invoice</strong>
                                                            </Text>
                                                        )
                                                }
                                            </Pane>
                                        </Pane>
                                        <Pane display="flex" flexDirection="column" width="50%">

                                            <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                                                Payment Details
                                            </Heading>
                                            <Pane display="flex" flexDirection="column" marginBottom={24}>

                                                {/* Will open this up when creating invoice.
                                            <Text marginBottom={4}>
                                                <strong>Invoice Number: </strong>
                                                ??
                                            </Text>

                                            <Text marginBottom={4}>
                                                        <strong>Order Date: </strong>
                                                ??
                                            </Text>
                                            <Text marginBottom={4}>
                                                        <strong>Renewal Due Date: </strong>
                                                ??
                                            </Text>
                                            <Text marginBottom={4}>
                                                <strong>Renewal On Date: </strong>
                                                ??
                                            </Text> */}
                                                <Text marginBottom={4}>
                                                    <strong>Payment Date: </strong>
                                                    {receiptData?.paymentDate ? moment.utc(receiptData?.paymentDate).format('M/D/YYYY') : ''}
                                                </Text>
                                                {receiptData?.collectingMethod === "AUTO" ?
                                                    <>
                                                        <Text marginBottom={4}>
                                                            <strong>Paid Via: </strong>
                                                            {receiptData?.paidVia}
                                                        </Text>
                                                        {receiptDetails?.last4 ?
                                                            <Text marginTop={0}>
                                                                <strong>Credit Card: </strong>
                                                                xxxx-xxxx-xxxx-{receiptDetails?.last4}
                                                            </Text> : <></>
                                                        }
                                                        <Text marginBottom={4}>
                                                            <strong>Transaction: </strong>
                                                            {receiptData?.gatewayTransactionId}
                                                        </Text>
                                                    </> :
                                                    <>
                                                        <Text marginBottom={4}>
                                                            <strong>Paid Via: </strong>
                                                            Collected off portal
                                                        </Text>
                                                        <Text marginBottom={4}>
                                                            <strong>Payment Note: </strong>
                                                            {receiptData?.paymentNote}
                                                        </Text>
                                                    </>
                                                }
                                            </Pane>
                                        </Pane>
                                    </Pane>
                                    <Pane display="flex" alignItems="center" width="100%"
                                        justifyContent="center"
                                        border="default"
                                    >

                                        <Table width="100%">
                                            <Table.Body>
                                                <Table.Body>
                                                    <Table.Head>
                                                        <Table.TextHeaderCell flexBasis={200} >
                                                            <p className='text-left' >Fee Type</p>
                                                        </Table.TextHeaderCell>
                                                        <Table.TextHeaderCell>
                                                            <p className='text-right' >{`Fee's(USD)`}</p>
                                                        </Table.TextHeaderCell>
                                                        <Table.TextHeaderCell>
                                                            <p className='text-right'  >Quantity</p>
                                                        </Table.TextHeaderCell>
                                                        <Table.TextHeaderCell >
                                                            <p className='text-right' >{`Amount(USD)`}</p>
                                                        </Table.TextHeaderCell>
                                                    </Table.Head>
                                                    <Table.Body>
                                                        <Table.Row >
                                                            <Table.TextCell flexBasis={200}>
                                                                {(() => {
                                                                    switch (receiptDetails?.type) {
                                                                        case 'SUBS':
                                                                            return 'Monthly Platform Fee for ' + receiptDetails?.plan
                                                                        case 'OTHER':
                                                                            return "One Time fee for " + receiptDetails?.plan
                                                                        case 'ADDITIONAL_FEE':
                                                                            return "Monthly Usage Fee for " + receiptDetails?.plan
                                                                        default:
                                                                            return receiptDetails?.type
                                                                    }
                                                                })()}
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right' >{receiptData?.amount?.toFixed(2)}</p>
                                                            </Table.TextCell>
                                                            <Table.TextCell marginRight={13}  >
                                                                <p className='text-right'>1</p>
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right'>{receiptData?.amount?.toFixed(2)} </p>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                        <Table.Row>
                                                            <Table.TextCell flexBasis={200}></Table.TextCell>
                                                            <Table.TextCell marginRight={11} className="text-right">
                                                                <b className="text-right" >Sub Total</b>
                                                            </Table.TextCell>
                                                            <Table.TextCell></Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right' ><b className="text-right">
                                                                    {receiptData?.amount?.toFixed(2)} </b></p>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table.Body>
                                            </Table.Body>
                                        </Table>
                                    </Pane>

                                    <Pane display="flex" marginTop="2%" alignItems="center" width="100%" >
                                        <Pane display="flex" flexDirection="column" width="50%">
                                            <Table width="100%">
                                                <Table.Body>
                                                    <Table.Body>
                                                        <Table.Row style={{ border: 'none' }}>
                                                        </Table.Row>


                                                        <Table.Row style={{ border: 'none' }}>
                                                        </Table.Row>

                                                        {!(receiptData?.refundStatus === PaymentStatus.REFUND_COMPLETED || receiptData?.refundStatus === PaymentStatus.REFUND_REQUESTED) &&
                                                            <Table.Row style={{ border: 'none' }}>
                                                            </Table.Row>
                                                        }

                                                        <Table.Row>
                                                            <Table.TextCell >
                                                                <b>Paid on</b>
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right'>
                                                                    {receiptData?.paymentDate ? moment.utc(receiptData?.paymentDate).format('M/D/YYYY') : ''}</p>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                        {(receiptData?.refundStatus === PaymentStatus.REFUND_COMPLETED || receiptData?.refundStatus === PaymentStatus.REFUND_REQUESTED) &&
                                                            <Table.Row>
                                                                <Table.TextCell >
                                                                    <b>{receiptData?.refundStatus === PaymentStatus.REFUND_COMPLETED ? 'Refunded on' : 'Refund Requested on'}</b>
                                                                </Table.TextCell>
                                                                <Table.TextCell>
                                                                    <p className='text-right'>
                                                                        {receiptData?.refundedAt ? moment.utc(receiptData?.refundedAt).format('M/D/YYYY') : ''}</p>
                                                                </Table.TextCell>
                                                            </Table.Row>
                                                        }
                                                    </Table.Body>
                                                </Table.Body>
                                            </Table>

                                        </Pane>
                                        <Pane display="flex" flexDirection="column" width="50%">
                                            <Table width="100%">
                                                <Table.Body>
                                                    <Table.Body>
                                                        <Table.Row>
                                                            <Table.TextCell >
                                                                Tax Rate
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right'>0%</p>
                                                            </Table.TextCell>

                                                        </Table.Row>


                                                        <Table.Row>
                                                            <Table.TextCell >
                                                                Tax Amount
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <p className='text-right'>0.00 </p>
                                                            </Table.TextCell>

                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell >
                                                                <b>Total</b>
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <b><p className='text-right'>
                                                                    {receiptData?.amount?.toFixed(2)} </p></b>
                                                            </Table.TextCell>

                                                        </Table.Row>

                                                        <Table.Row>
                                                            <Table.TextCell >
                                                                <b>Total Amount Due</b>
                                                            </Table.TextCell>
                                                            <Table.TextCell>
                                                                <b><p className='text-right'>
                                                                    0.00 </p></b>
                                                            </Table.TextCell>
                                                        </Table.Row>
                                                    </Table.Body>
                                                </Table.Body>
                                            </Table>
                                        </Pane>
                                    </Pane>
                                </Pane>

                                <Pane
                                    gridArea="footer"
                                    elevation={0}
                                    padding={16}
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Pane display="flex" width="100%" >
                                        {/* <Pane display="flex" flexDirection="column" width="20%"> */}
                                        {/* {   // Don't allow email receipt for services.
                                    isRefundable ?
                                        <Button
                                            className="no-print"
                                            type="submit"
                                            intent="danger"
                                            height={48}
                                            marginLeft={10}
                                            onClick={() => setRefundDialogShown(true)}
                                        >Refund</Button> : <></>
                                } */}

                                        <Button
                                            className="no-print"
                                            type="submit"
                                            appearance="primary"
                                            marginLeft={10}
                                            height={48}
                                            onClick={() => window.print()}
                                        >
                                            Print
                                        </Button>
                                        {/* {   // Don't allow email receipt for services.
                                    tanentType !== 'service' ?
                                        <Button
                                            className="no-print"
                                            type="submit"
                                            appearance="primary"
                                            marginLeft={10}
                                            height={48}
                                            onClick={() => EmailReceipt()}
                                        >
                                            Email Receipt
                                        </Button> : <></>
                                } */}
                                        <Button
                                            className="button-danger"
                                            type="button"
                                            height={48}
                                            marginLeft={10}
                                            onClick={() => downloadMemberPDF()}
                                        >
                                            Download PDF
                                        </Button>

                                        {/* {
                                    isVoidable ?
                                        <Button
                                            className="no-print"
                                            type="submit"
                                            appearance="primary"
                                            marginLeft={10}
                                            height={48}
                                            onClick={() => VoidPayment()}
                                        >
                                            Void
                                        </Button> : <></>
                                } */}
                                        {/* </Pane> */}
                                        {/* <Pane display="flex" flexDirection="column" width="50%">

                                    
                                </Pane> */}
                                    </Pane>


                                </Pane>
                            </Pane>
                        </SheetLayout>
                    )
                }



            </SideSheet>
        </>
    )
}


export default PaymentRecipt

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
  
`

