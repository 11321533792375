import { library } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { faPaperPlane } from '@fortawesome/pro-solid-svg-icons/faPaperPlane'
import { faPrint } from '@fortawesome/pro-solid-svg-icons/faPrint'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle'
import { faFilesMedical as faFilesMedical_s } from '@fortawesome/pro-solid-svg-icons/faFilesMedical'
import { faTimes as faTimes_s } from '@fortawesome/pro-solid-svg-icons/faTimes'

// REGULAR
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faFilesMedical as faFilesMedical_r } from '@fortawesome/pro-regular-svg-icons/faFilesMedical'
import { faCreditCard } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons/faChevronDown'

// LIGHT
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'

// DUOTONE
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faCheckCircle } from '@fortawesome/pro-duotone-svg-icons/faCheckCircle'
import { faTimes as faTimes_d } from '@fortawesome/pro-duotone-svg-icons/faTimes'
import { faShieldCheck } from '@fortawesome/pro-duotone-svg-icons/faShieldCheck'
import { faTruckLoading } from '@fortawesome/pro-duotone-svg-icons/faTruckLoading'

// BRAND

export const initializeIconLibrary = () => {
  library.add(
    faPaperPlane,
    faEnvelope,
    faLock,
    faUser,
    faFilesMedical_r,
    faFilesMedical_s,
    faCreditCard,
    faPrint,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faUserPlus,
    faCheckCircle,
    faInfoCircle,
    faChevronDown,
    faShieldCheck,
    faTruckLoading,
    faTimes_d,
    faTimes_s
  )
}
