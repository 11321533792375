import ViewMemberProfile from './view-member-profile'
import ViewMemberActivity from './view-member-activity'
import ViewMemberPlan from './view-member-plan'
import ViewMemberDependents from './view-member-dependents'
import ViewMemberBilling from './view-member-billling'

export default {
  Profile: ViewMemberProfile,
  Plan: ViewMemberPlan,
  Activity: ViewMemberActivity,
  Dependents: ViewMemberDependents,
  Billing: ViewMemberBilling
}
