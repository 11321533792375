import gql from 'graphql-tag'

// export const GET_COUPON = gql`
//   query GetCoupon($code: String!, $accountId: ID) {
//     coupon(code: $code, accountId: $accountId) {
//       id
//       valid
//       duration
//       duration_in_months
//       amount_off
//       percent_off
//     }
//   }
// `

export const GET_CONTACT_PLAN_SUBSCRIPTION = gql `
  query GetContactPlanSubscriptions($id: ID!){
    getContactPlanSubscriptions(id: $id){
      id
      creditCard_lastfour
    }
  }
`
