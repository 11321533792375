import React from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'
import moment from 'moment'

import { ApolloClient } from 'apollo-client'
import { useQuery } from '@apollo/react-hooks'

import { GET_CONTRACT_SHEET } from '../graphql/_contract-sheet'

import { SideSheet, Pane, Paragraph, Text, Heading } from 'evergreen-ui'
import CardHeader from '../card-header'
import Spinner from '../spinner'

import TreatmentsTable from '../treatments-table'
import DiscountsTable from '../discounts-table'

import { effectiveDateRangeInDateTime } from '../utility/formatters'
import { colors } from '../utility/theme'
import { useMediaQueryContext } from '../media-query-provider'
import MembershipContract from '../contract/membership-contract'
import Button from '../button'
import axios from 'axios'
import { auth } from 'firebase'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  planId: string
  effectiveDate?: any
  endDate?: any
  planTerm?: Types.PlanTerm
  cancelled?: boolean
  apolloClient?: ApolloClient<any>
  dependent?: boolean
}

const ContractSheet = ({
  isShown,
  setIsShown,
  planId,
  effectiveDate,
  endDate,
  planTerm,
  cancelled,
  apolloClient,
  dependent
}: Props) => {
  const { isMobile } = useMediaQueryContext()
  const { loading, error, data } = useQuery<Types.ContractSheet, Types.ContractSheetVariables>(GET_CONTRACT_SHEET, {
    variables: { id: planId! },
    skip: !isShown || !planId,
    client: apolloClient,
  })
  const plan = data?.plan
  const annualPrice = plan?.annualPrice ?? 0
  const annualDependentPrice = plan?.annualDependentPrice ?? 0
  const monthlyPrice = plan?.monthlyPrice ?? 0
  const monthlyDependentPrice = plan?.monthlyDependentPrice ?? 0
  
  const dependentDiscount = dependent && plan?.dependentDiscount ? plan.dependentDiscount : 0
  const price = plan ? `${(!planTerm && plan.annualPriceActive) || planTerm === Types.PlanTerm.ANNUAL ? `$${dependent ? annualDependentPrice : annualPrice} / year` : ''} ${!planTerm && plan.annualPriceActive && plan.monthlyPriceActive ? ' or ' : ''} ${(!planTerm && plan.monthlyPriceActive) || planTerm === Types.PlanTerm.MONTHLY ? `$${dependent ? monthlyDependentPrice : monthlyPrice} / month` : ''} ${dependent && dependentDiscount > 0 ? `(${dependentDiscount}% Dependent Discount)` : ''}` : ''
  const accountToken = () => auth().currentUser?.getIdToken();

  const downloadMemberPDF = () => {
    accountToken && accountToken()?.then((token: string) => {
      
        axios({
            method: 'post',
            url: (process.env.REACT_APP_URL_HOST ?? '') + "download/contract", //'http://localhost:4000/download/contract',
            // url: process.env.REACT_APP_URL_HOST ?? '' + "download/contract/" + tanentType, //'http://localhost:4000/download/receipt',
            responseType: 'blob',
            headers: { Authorization: `Bearer ${token}` },
            data: {
              ...plan,
              effectiveDates: effectiveDateRangeInDateTime(effectiveDate, endDate),
              cancelled: cancelled,
              renewalDate: `${(effectiveDate ? moment.utc(endDate).add(1, "days").format('M/D/YYYY') : moment().add(1, 'year').format('M/D/YYYY'))}`,
              price: price
            }
        }).then((response) => {
            const file = new Blob([response.data], {
                type: 'application/pdf',
            });

            const fileURL = URL.createObjectURL(file);

            window.open(fileURL);
        }, (error) => {
            console.log(error);
        });
    }).catch((err: any) => {
        console.log(err);
    })
};

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
      shouldCloseOnOverlayClick= {false}
    >
      {loading || error || !plan ? (
        <Pane
          display="flex"
          alignItems="center"
          background="blueTint"
          height={isMobile ? 'calc(100vh - 56px)' : '100%'}
        >
          <Spinner delay={0} />
        </Pane>
      ) : (
          <SheetLayout>
            <CardHeader gridArea="header">
              <Heading size={600}>{plan?.account?.name ?? plan.name}</Heading>
              {/* <Button iconBefore={['fas', 'print']} onClick={() => window.print()}>
              Print
            </Button> */}
            </CardHeader>

            <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
              <Pane margin={24}>
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  Overview
              </Heading>
                <Paragraph marginBottom={24}>
                  We offer an in-house membership plan to our loyal patients that provides easy, comprehensive, and
                  affordable dental care benefits.
              </Paragraph>
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  Plan Details
              </Heading>
                <Pane display="flex" flexDirection="column" marginBottom={24}>
                  <Text overflowWarp="break-word" wordWrap="break-word" marginBottom={4}>
                    <strong>Plan: </strong>
                    {plan.name}
                  </Text>
                  <Text marginBottom={4}>
                    <strong>Effective Dates: </strong>
                    {effectiveDateRangeInDateTime(effectiveDate, endDate)}
                  </Text>

                  <Text marginBottom={4}>
                    <strong>{cancelled ? 'Termination' : 'Renewal'} Date: </strong>
                    {`${(effectiveDate ? moment.utc(endDate).add(1, "days").format('M/D/YYYY') : moment().add(1, 'year').format('M/D/YYYY'))}`}
                  </Text>
                  <Text marginBottom={4}>
                    <strong>Renewal: </strong>
                    {cancelled ? 'Cancelled' : 'Annual'}
                  </Text>

                  <Text marginBottom={4}>
                    <strong>Price: </strong>
                    {price}
                    {/* {`
                    ${(!planTerm && plan.annualPriceActive) || planTerm === Types.PlanTerm.ANNUAL
                        ? `$${dependent ? annualDependentPrice : annualPrice} / year`
                        : ''
                      }
                    ${!planTerm && plan.annualPriceActive && plan.monthlyPriceActive ? ' or ' : ''}
                    ${(!planTerm && plan.monthlyPriceActive) || planTerm === Types.PlanTerm.MONTHLY
                        ? `$${dependent ? monthlyDependentPrice : monthlyPrice} / month`
                        : ''
                      }
                    ${dependent && dependentDiscount > 0 ? ` (${dependentDiscount}% Dependent Discount)` : ''}
                  `} */}
                  </Text>
                  {plan.showValue && (
                    <Text marginBottom={4}>
                      <strong>Annual Value: </strong>
                      <PlanValueString plan={plan} />
                    </Text>
                  )}
                </Pane>
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={16}>
                  Included Treatments
              </Heading>
                <TreatmentsTable
                  marginBottom={24}
                  elevation={0}
                  treatments={plan.treatments}
                  showValue={plan.showValue}
                />
                {((plan.globalDiscountActive && plan.globalDiscount && plan.globalDiscount > 0) ||
                  !!plan.discounts.length) && (
                    <>
                      <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                        Procedure Discounts by Category
                  </Heading>
                      <Paragraph marginBottom={16}>
                        The following highlights the discounts provided for each dental procedure category as part of this
                        membership plan:
                  </Paragraph>
                      <DiscountsTable marginBottom={24} elevation={0} plan={plan} />
                    </>
                  )}
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                  Dental Services Membership Agreement
              </Heading>
              <MembershipContract additionalTerms={plan?.additionalTerms }/>
              <Pane display="flex" justifyContent="flex-end" marginTop={16}>
                <Button
                  className="button-danger"
                  type="button"
                  height={48}
                  marginLeft={10}
                  onClick={() => downloadMemberPDF()}
                  >
                  Download PDF
                </Button>
              </Pane>
                { /*
              <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8}>
                Terms, Exclusions, & Disclosures
              </Heading>
              <Pane marginBottom={24}>
                <Paragraph>
                  You agree to the following terms and disclosures by purchasing this membership plan:
                </Paragraph>
                <UnorderedList>
                  <ListItem>[Placeholder for TruBlu text]</ListItem>

                  {plan.additionalTerms && <ListItem>{plan.additionalTerms}</ListItem>}
                </UnorderedList>
              </Pane> 
              */ }
              </Pane>
            </Pane>
          </SheetLayout>
        )}
    </SideSheet>
  )
}

const PlanValueString = ({ plan }: { plan: Types.ContractSheet_plan }) => {
  const totalValue = plan.treatments.reduce((prev, curr) => prev + curr.value, 0)
  const annualCost = plan.annualPriceActive ? plan.annualPrice! : plan.monthlyPrice! * 12
  return (
    <span>
      {`$${totalValue} `} <i>{`(Save $${totalValue - annualCost} compared to our standard fees)`}</i>
    </span>
  )
}

export default ContractSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
