import React, { useState } from 'react'
import moment from 'moment'
import { Table, Text } from 'evergreen-ui'
import Icon from './icon'
import MemberReceipt from './_sheets/member-receipt'

import FailedContactPayment from './_sheets/failed-contact-payment'

export type Props = {
  charges: {
    createdAt: any
    paymentDate: string | null
    invoiceDate: string | null
    status: string
    amount: number
    plan: string
    last4: string
    cardExp: string | null
    isDependent: boolean,
    id: string,
    invoiceNumber: string | null,
    paymentErrorMessage: string | null
    subscriptionId: string | null
  }[],
  accountToken: any,
  hostUrl: string,
  tanentType?: string,
  isAdmin?: boolean
}


const PaymentHistoryTable = ({ charges, accountToken, hostUrl, tanentType, isAdmin=false }: Props) => {
  const [isReceiptSheetShown, setIsReceiptSheetShown] = useState(false)
  const [contactPaymentId, setContactPaymentId] = useState('')

  const [isServiceSheetShown, setIsServiceSheetShown] = useState(false)
  const [serviceCharge, setServiceCharge] = useState({})

  


  // console.warn(accountToken);
  //console.warn('PaymentHistoryTable:');
  //return false;

  return (
    <>
    
{ contactPaymentId != '' ?
        <MemberReceipt
          isShown={isReceiptSheetShown}
          setIsShown={setIsReceiptSheetShown}
          paymentEventId={contactPaymentId}
          getAccountToken={accountToken}
          hostUrl={hostUrl}
          tanentType={tanentType}
          isAdmin={isAdmin}
        /> : <></>
      }

      
{ serviceCharge != '' ?
        <FailedContactPayment
          isShown={isServiceSheetShown}
          setIsShown={setIsServiceSheetShown}
          charge={serviceCharge}
          hostUrl={hostUrl}
          isAdmin={isAdmin}
        /> : <></>
      }

      <Table borderRadius="5px" textAlign="left">
        <Table.Head height={44}>
          <Table.TextHeaderCell flexBasis={88} flexShrink={0} flexGrow={0}>
            Invoice Date
          </Table.TextHeaderCell>
          <Table.TextHeaderCell flexBasis={88} flexShrink={0} flexGrow={0}>
            Payment Date
          </Table.TextHeaderCell>
          <Table.TextHeaderCell>{`Amount(USD)`}</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {charges.map((charge, i) => (
            <Table.Row key={i} height="auto" minHeight={48} paddingY={12}
              cursor="pointer"
              isSelectable
              intent={charge.status === 'COMPLETE' ? 'success' : 'danger'}
              onSelect={() => {
                if (charge.status === 'COMPLETE') {
                  setContactPaymentId(charge.id);
                  setIsReceiptSheetShown(true);
                }else if(charge.status !== 'PENDING') {
                  setServiceCharge(charge);
                  setIsServiceSheetShown(true);                             
                }
              }}
            >
              <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                {charge?.invoiceDate}
              </Table.TextCell>
              <Table.TextCell flexBasis={88} flexShrink={0} flexGrow={0}>
                {console.log(charge)}
                {charge.paymentDate ?? moment(charge.createdAt).format('M/D/YYYY')}
              </Table.TextCell>
              <Table.Cell display="flex">
                <Icon icon={['far', 'dollar-sign']} marginRight={4} fontSize={12} width={16} flexShrink={0} />
                <Text overflowWarp="break-word" paddingRight={2} width={298} wordWrap="break-word" size={300}>
                  {charge.invoiceNumber ? 'Invoice: '+charge.invoiceNumber.toUpperCase()+',  ': ''} 
                  {charge.status === 'COMPLETE' ? 'Charged' : charge.status === 'FAILED' ? 'Failed' : charge.status} $
                  {charge.amount} - {charge.plan} <br />
                  {charge.last4 != '' ?
                    <i>Card ending in {charge.last4} for {charge.isDependent ? 'Dependent' : 'Self'}</i> :
                    <i>Collected offline for {charge.isDependent ? 'Dependent' : 'Self'}</i>
                  }
                  
                </Text>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  )
}
export default PaymentHistoryTable
