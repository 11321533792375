import React, { useState } from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'

import { useQuery } from '@apollo/react-hooks'
import { GET_VIEW_MEMBER_SHEET } from '../graphql/_view-member-sheet'

import { SideSheet, Pane, Tablist, Tab, Avatar, Text, Heading } from 'evergreen-ui'

import CardHeader from '../card-header'
import Spinner from '../spinner'
import { isJson } from '../utility/transforms'

import ViewMember from '../view-member'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  memberId: string
  isAdmin?: boolean
  memberApp?: boolean
  initialPath?: 'plan' | 'profile' | 'activity' | 'dependents' | 'billing',
  accountToken?: any
  tanentType: string
}

const steps = [
  { path: 'plan' as const, component: ViewMember.Plan },
  { path: 'profile' as const, component: ViewMember.Profile },
  { path: 'billing' as const, component: ViewMember.Billing },
  { path: 'activity' as const, component: ViewMember.Activity },
  { path: 'dependents' as const, component: ViewMember.Dependents }
]

const ViewMemberSheet = ({ isShown, setIsShown, memberId, isAdmin = false, memberApp = false, initialPath = 'plan', accountToken, tanentType }: Props) => {
  if (memberApp) initialPath = 'profile'
  const [currentPath, setCurrentPath] = useState(initialPath)
  const CurrentView = steps.find(step => step.path === currentPath)!.component

  const { loading, error, data } = useQuery<Types.ViewMemberSheet, Types.ViewMemberSheetVariables>(
    GET_VIEW_MEMBER_SHEET,
    {
      fetchPolicy: 'no-cache',
      context: { secure: true },
      variables: { id: memberId },
      skip: !isShown
    }
  )

  const secureContact =
    data?.contact?.secure && isJson(data.contact.secure)
      ? (JSON.parse(data.contact.secure) as Types.SecureContact)
      : { email: "unknown@pfh.com", firstName: "error", lastName: "error", phone: "6121110000", dobMonth: 1, dobDay: 1, dobYear: 1900, perio: false }

  const shownSteps = memberApp ? [steps[1], steps[2]] : data?.contact?.parent ? steps.slice(0, 4) : steps

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={600}
      containerProps={{
        display: 'flex',
        flex: '1',
        flexDirection: 'column'
      }}
      shouldCloseOnOverlayClick= {false}
    >
      {loading || error || !data?.contact || !secureContact ? (
        <Pane display="flex" alignItems="center" height="100%" background="blueTint">
          <Spinner delay={0} />
        </Pane>
      ) : (
        <SheetLayout>
          <CardHeader gridArea="header" padding={0} paddingLeft={0} flexDirection="column" alignItems="stretch">
            <Pane display="flex" justifyContent="space-between" padding={16} paddingRight={24} borderBottom="muted">
              <Pane display="flex" alignItems="center">
                <Avatar name={`${secureContact.firstName} ${secureContact.lastName}`} size={40} />
                <Pane marginLeft={16}>
                  <Heading size={600}>
                    {secureContact.firstName} {secureContact.lastName}
                  </Heading>
                  <Text size={400}>{data.contact.parent ? 'Dependent' : 'Member'} Overview</Text>
                </Pane>
              </Pane>
              <Text size={400}>
                <strong>ID: </strong>
                {data.contact.code.toUpperCase()}
              </Text>
            </Pane>
            <Pane display="flex" padding={8}>
              <Tablist>
                {shownSteps.map(step => {
                  const { path } = step
                  return (
                    <Tab
                      key={path}
                      textTransform="uppercase"
                      isSelected={currentPath === path}
                      onSelect={() => setCurrentPath(path)}
                    >
                      {path}
                    </Tab>
                  )
                })}
              </Tablist>
            </Pane>
          </CardHeader>

          <Pane gridArea="body" overflow="hidden">
            <CurrentView tanentType={tanentType ?? ''} accountToken={accountToken} isAdmin={isAdmin || !memberApp} contact={data.contact} secureContact={secureContact} />
          </Pane>
        </SheetLayout>
      )}
    </SideSheet>
  )
}

export default ViewMemberSheet

const SheetLayout = styled.div`
  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'header'
    'body';
`
