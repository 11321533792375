import React from 'react'
import { useField } from 'formik'

import { SelectMenu as EverGreenSelectMenu, SelectMenuProps } from 'evergreen-ui'
import Button from './button'

export interface Props extends Omit<SelectMenuProps, 'children'> {
  name: string
  buttonWidth?: number | string
  height?: number
  placeholder?: string
  marginBottom?: number
}

const SelectMenu = ({
  name,
  options = [],
  buttonWidth,
  height = 48,
  placeholder,
  marginBottom = 16,
  ...props
}: Props) => {
  const [field] = useField(name)
  const selected = options.find(option => option.value === field.value)

  return (
    <EverGreenSelectMenu
      closeOnSelect={true}
      {...props}
      options={options}
      height={33 * options.length}
      onSelect={e => field.onChange({ target: { name, value: e.value } })}
      hasFilter={false}
      hasTitle={false}
    >
      <Button
        width={buttonWidth}
        height={height}
        marginBottom={marginBottom}
        display="flex"
        justifyContent="space-between"
        iconAfter={['far', 'chevron-down']}
      >
        {(selected && selected.label) ?? placeholder}
      </Button>
    </EverGreenSelectMenu>
  )
}

export default SelectMenu
