

export function VgsWrap<T>(value: T): T | string {
    const skipVgs = process.env.REACT_APP_SKIPVGS ?? process.env.GATSBY_SKIPVGS ?? null
    if(!skipVgs){
        return value
    }
    else{
        return JSON.stringify(value)
    }
}
  