import React from 'react'

import { Heading, Pane, CardProps, Text } from 'evergreen-ui'
import Card from './card'
import CardHeader from './card-header'
import Button from './button'
import Icon from './icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends CardProps {
  header?: {
    title: string
    button?: React.ReactNode
  }
  links: { title: string; href: string; icon: IconProp }[]
}

const LinkCard = ({ header, links, ...props }: Props) => (
  <Card padding={0} {...props}>
    {header && (
      <CardHeader withButton={!!header.button}>
        <Heading size={500}>{header.title}</Heading>
        {header.button}
      </CardHeader>
    )}
    <Pane flexGrow={1}>
      {links.map((link, i) => (
        <Button
          key={i}
          is="a"
          href={link.href}
          target="_blank"
          size={400}
          appearance="minimal"
          height={48}
          paddingX="16px"
          display="flex"
          justifyContent="space-between"
        >
          <Text size={400} color="selected" overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {link.title}
          </Text>
          <Icon icon={link.icon} color="primary" marginLeft={16} flexShrink={0} />
        </Button>
      ))}
    </Pane>
  </Card>
)

export default LinkCard
