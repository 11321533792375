import React, { useState } from 'react'
//import * as Types from '../types'
import styled from 'styled-components/macro'
// import moment from 'moment'

import { SideSheet, Pane, Heading, toaster } from 'evergreen-ui'
// import FormError from '../form-error'
import PlanCardList from '../plan-card-list'
import CardHeader from '../card-header'
// import Spinner from '../spinner'
import { Formik, Form } from 'formik'
// import * as Yup from 'yup'
import { useApolloClient } from '@apollo/react-hooks'
// import TextInputField from '../text-input-field'
//import { getShownPrice } from '../utility/transforms'

import Button from '../button'

import { colors } from '../utility/theme'
import { useMediaQueryContext } from '../media-query-provider'
import { PlanTerm } from 'types'
// import { useMutation } from '@apollo/react-hooks'

export type Props = {
  isShown: boolean
  setIsShown: (isShown: boolean) => void
  currentPlanId: string
  currentTerm: PlanTerm | null
  updatePlan: (planId: string, term: PlanTerm) => void
}


const UpdatePlanSheet = ({
  isShown,
  setIsShown,
  currentPlanId,
  currentTerm,
  updatePlan

}: Props) => {
  const { isMobile } = useMediaQueryContext()

  const apolloClient = useApolloClient()
  // const [update] = useMutation<Types.updateStartEndDate, Types.updateStartEndDateVariables>(UPDATE_START_END_DATE)

  const [selectedPlanId, setSelectedPlanId] = useState(currentPlanId)
  const [selectedTerm, setSelectedTerm] = useState(currentTerm)

  const selectPlan = (
    plan: {
      id: string
    },
    term: PlanTerm
  ) => {
    setSelectedPlanId(plan.id)
    setSelectedTerm(term)
  }
  const unSelectPlan = () => {
    setSelectedTerm(null)
  }

  return (
    <SideSheet
      isShown={isShown}
      onCloseComplete={() => setIsShown(false)}
      width={500}
      preventBodyScrolling={true}
      //@ts-ignore
      position={isMobile ? 'bottom' : 'right'}
      shouldCloseOnOverlayClick= {false}
    >

      <Formik initialValues={{

      }}
        onSubmit={() => { if(selectedTerm) updatePlan(selectedPlanId, selectedTerm)
                          else toaster.danger('You must select a plan.')
         }}
      >
        <Form style={{ height: '100%', width: '100%' }}>
          <SheetLayout>
            <CardHeader gridArea="header">
              <Heading size={600}>Change Plan</Heading>
            </CardHeader>

            {/* <Pane gridArea="body" overflow="scroll" height={isMobile ? 'calc(100vh - 112px)' : '100%'} background="blueTint"> */}
            <Pane
              gridArea="body"
              overflow="hidden"
              display="flex"
              flexDirection="column"
              background="blueTint"
              paddingTop={0}
              height={isMobile ? 'calc(100vh - 112px)' : '100%'}>
              <CardHeader gridArea="header">
                <Heading color={colors.blue.base} textTransform="uppercase" marginBottom={8} margin={8}>
                  Current Plan Term: {currentTerm}
                    </Heading>
              </CardHeader>

              <PlanCardList
                selectPlan={selectPlan}
                unSelectPlan={unSelectPlan}
                selectedPlanId={selectedPlanId}
                currentPlanTerm={currentTerm}
                // selectedPlanId={planState?.id}
                // age={moment().diff(
                //   moment(`${detailsState?.dobYear}-${detailsState?.dobMonth}-${detailsState?.dobDay}`),
                //   'years'
                // )}
                //perio={true}
                dependent={false}
                apolloClient={apolloClient}
                width="100%"
              />
              <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end">
                <Button
                  disabled={currentPlanId === selectedPlanId && selectedTerm === currentTerm}
                  type="submit"
                  appearance="primary"
                  height={48}
                  justifyContent="center"
                  iconAfter={['far', 'chevron-right']}
                >
                  Update
                </Button>
              </Pane>
            </Pane>
          </SheetLayout>
        </Form>
      </Formik>
    </SideSheet>
  )
}

export default UpdatePlanSheet

const SheetLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'header'
    'body';
  grid-template-rows: auto 1fr;
`
