import React from 'react'
import { CardElement as StripeCardElement } from 'react-stripe-elements'
import { Pane } from 'evergreen-ui'

import { colors, fontFamily } from './utility/theme'

const CardElement = () => (
  <Pane width="100%">
    <StripeCardElement
      style={{
        base: {
          fontFamily,
          fontSize: '16px',
          color: colors.neutral.base
        },
        invalid: {
          color: colors.red.base
        }
      }}
    />
  </Pane>
)

export default CardElement
