import React, { useState } from 'react'

import { Pane, Dialog, Text, IntentTypes } from 'evergreen-ui'

export type Props = {
  isShown: boolean
  setIsShown: React.Dispatch<React.SetStateAction<boolean>>
  confirm?: () => void
  cancel?: () => void
  title?: string
  body?: string
  confirmLabel?: string
  intent?: IntentTypes
}

const ConfirmDialog = ({ isShown, setIsShown, title, body, confirm, cancel, intent, confirmLabel }: Props) => {
  const [confirmed, setConfirmed] = useState(false)

  return (
    <Dialog
      isShown={isShown}
      title={title ?? 'Confirmation'}
      onCloseComplete={() => {
        setIsShown(false)
        if (confirmed) confirm && confirm()
        else cancel && cancel()
      }}
      confirmLabel={confirmLabel}
      onConfirm={close => {
        setConfirmed(true)
        close()
      }}
      onCancel={close => {
        setConfirmed(false)
        close()
      }}
      intent={intent}
    >
      <Pane padding={16}>
        <Text>{body ?? 'Please confirm you would like to proceed'}</Text>
      </Pane>
    </Dialog>
  )
}

export default ConfirmDialog
