import React from 'react'

import { Pane, TextInput, TextInputProps, IntentTypes } from 'evergreen-ui'
import Button from './button'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends TextInputProps {
  name: string
  label: string
  disabled?: boolean
  isButtonLoading?: boolean
  buttonIntent?: IntentTypes
  buttonOnClick?: () => void
  buttonIconBefore?: IconProp
  buttonIconAfter?: IconProp
  autoFocus?: boolean
  buttonType?: HTMLButtonElement['type']
}

const TextInputWithButton = ({
  label,
  disabled,
  buttonIntent,
  isButtonLoading,
  buttonAppearance,
  buttonOnClick,
  buttonIconBefore,
  buttonIconAfter,
  autoFocus,
  width,
  buttonType,
  ...props
}: Props) => {
  const { height = 48, maxWidth } = props

  return (
    <Pane position="relative" display="flex" width={width} maxWidth={maxWidth}>
      <TextInput
        {...props}
        borderTopRightRadius={0}
        borderBottomRightRadius={0}
        marginRight={-1}
        flex={1}
        position="relative"
        zIndex={1}
        height={height}
      />
      <Button
        isLoading={isButtonLoading}
        intent={buttonIntent}
        appearance={buttonAppearance}
        onClick={buttonOnClick}
        borderTopLeftRadius={0}
        borderBottomLeftRadius={0}
        iconBefore={buttonIconBefore}
        iconAfter={buttonIconAfter}
        height={height}
        autoFocus={autoFocus}
        type={buttonType}
        disabled={disabled}
      >
        {label}
      </Button>
    </Pane>
  )
}

export default TextInputWithButton
