import React from 'react'

import { Text, Table, TableProps } from 'evergreen-ui'
import Icon from './icon'

import { useMediaQueryContext } from './media-query-provider'

export interface Props extends TableProps {
  treatments: {
    name: string
    code: string
    quantity: number
    value: number
  }[]
  showValue?: boolean
}

const TreatmentsTable = ({ treatments, showValue, ...props }: Props) => {
  const { isMobile } = useMediaQueryContext()

  return !isMobile ? (
    <Table {...props}>
      <Table.Head height={44}>
        <Table.TextHeaderCell flexBasis={240} flexShrink={0} flexGrow={0}>
          Description
        </Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="center">CDT Code</Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="center" flexBasis={88} flexShrink={0} flexGrow={0}>
          Qty/Yr
        </Table.TextHeaderCell>
        <Table.TextHeaderCell textAlign="right">Value</Table.TextHeaderCell>
      </Table.Head>
      {treatments.map((treatment, i) => (
        <Table.Row key={i} height="auto" minHeight={40} paddingY={8}>
          <Table.Cell flexBasis={240} flexShrink={0} flexGrow={0}>
            <Icon icon={['fad', 'shield-check']} color="primary" marginRight={8} />
            <Text size={300}>{treatment.name}</Text>
          </Table.Cell>
          <Table.TextCell textAlign="center">{treatment.code}</Table.TextCell>
          <Table.TextCell textAlign="center" flexBasis={88} flexShrink={0} flexGrow={0}>
            {treatment.quantity > 0 ? treatment.quantity : treatment.quantity === -1 ? 'N/A' : 'As Needed'}
          </Table.TextCell>
          <Table.TextCell textAlign="right">${treatment.value}</Table.TextCell>
        </Table.Row>
      ))}
      {showValue && (
        <Table.Row height="auto" minHeight={40} paddingY={8}>
          <Table.TextCell flexBasis={240} flexShrink={0} flexGrow={0}>
            <strong>Total</strong>
          </Table.TextCell>
          {/* Placeholders to keep table size uniform */}
          <Table.Cell></Table.Cell>
          <Table.Cell flexBasis={88} flexShrink={0} flexGrow={0}></Table.Cell>
          <Table.TextCell textAlign="right">
            <strong>${treatments.reduce((prev, curr) => prev + curr.value, 0)}</strong>
          </Table.TextCell>
        </Table.Row>
      )}
    </Table>
  ) : (
    <Table {...props}>
      <Table.Head height={44}>
        <Table.TextHeaderCell>Description</Table.TextHeaderCell>

        <Table.TextHeaderCell textAlign="center" flexBasis={88} flexShrink={0} flexGrow={0}>
          Qty/Yr
        </Table.TextHeaderCell>
      </Table.Head>
      {treatments.map((treatment, i) => (
        <Table.Row key={i} height="auto" minHeight={40} paddingY={8}>
          <Table.Cell flexBasis={240} flexShrink={0} flexGrow={0}>
            <Icon icon={['fad', 'shield-check']} color="primary" marginRight={8} />
            <Text size={300}>{treatment.name}</Text>
          </Table.Cell>
          <Table.TextCell textAlign="center" flexBasis={88} flexShrink={0} flexGrow={0}>
            {treatment.quantity > 0 ? treatment.quantity : treatment.quantity === -1 ? 'N/A' : 'As Needed'}
          </Table.TextCell>
        </Table.Row>
      ))}
    </Table>
  )
}

export default TreatmentsTable
