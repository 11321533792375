import { library } from '@fortawesome/fontawesome-svg-core'

// SOLID
import { faIdCard } from '@fortawesome/pro-solid-svg-icons/faIdCard'
import { faUserPlus } from '@fortawesome/pro-solid-svg-icons/faUserPlus'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'

// REGULAR
import { faUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faFilesMedical as faFilesMedical_r } from '@fortawesome/pro-regular-svg-icons/faFilesMedical'
import { faCreditCard as faCreditCard_r } from '@fortawesome/pro-regular-svg-icons/faCreditCard'
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons/faChevronLeft'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons/faChevronRight'
import { faCommentAltLines as faCommentAltLines_r } from '@fortawesome/pro-regular-svg-icons/faCommentAltLines'
import { faDollarSign } from '@fortawesome/pro-regular-svg-icons/faDollarSign'

// LIGHT
import { faEnvelope } from '@fortawesome/pro-light-svg-icons/faEnvelope'
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock'

// DUOTONE
import { faIdCardAlt } from '@fortawesome/pro-duotone-svg-icons/faIdCardAlt'
import { faCreditCard as faCreditCard_d } from '@fortawesome/pro-duotone-svg-icons/faCreditCard'
import { faPlayCircle } from '@fortawesome/pro-duotone-svg-icons/faPlayCircle'

import { faStopwatch } from '@fortawesome/pro-duotone-svg-icons/faStopwatch'
import { faHeart } from '@fortawesome/pro-duotone-svg-icons/faHeart'
import { faWallet } from '@fortawesome/pro-duotone-svg-icons/faWallet'
import { faPhone } from '@fortawesome/pro-duotone-svg-icons/faPhone'
import { faBrowser } from '@fortawesome/pro-duotone-svg-icons/faBrowser'
import { faUnlock } from '@fortawesome/pro-duotone-svg-icons/faUnlock'
import { faHomeLgAlt } from '@fortawesome/pro-duotone-svg-icons/faHomeLgAlt'
import { faTooth } from '@fortawesome/pro-duotone-svg-icons/faTooth'
import { faCommentAltLines as faCommentAltLines_d } from '@fortawesome/pro-duotone-svg-icons/faCommentAltLines'
import { faQuestion } from '@fortawesome/pro-duotone-svg-icons/faQuestion'
import { faCheck } from '@fortawesome/pro-duotone-svg-icons/faCheck'
import { faAddressCard } from '@fortawesome/pro-duotone-svg-icons/faAddressCard'
import { faToothbrush } from '@fortawesome/pro-duotone-svg-icons/faToothbrush'
import { faFilesMedical as faFilesMedical_d } from '@fortawesome/pro-duotone-svg-icons/faFilesMedical'

// BRAND
import { faGoogle } from '@fortawesome/free-brands-svg-icons/faGoogle'
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons/faFacebookSquare'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons/faLinkedin'

export const initializeIconLibrary = () => {
  library.add(
    faIdCard,
    faIdCardAlt,
    faUserPlus,
    faUser,
    faFilesMedical_r,
    faFilesMedical_d,
    faCreditCard_r,
    faCreditCard_d,
    faEnvelope,
    faLock,
    faGoogle,
    faFacebookSquare,
    faLinkedin,
    faChevronLeft,
    faChevronRight,
    faStopwatch,
    faHeart,
    faWallet,
    faPhone,
    faBrowser,
    faUnlock,
    faUserCircle,
    faCommentAltLines_r,
    faHomeLgAlt,
    faTooth,
    faCommentAltLines_d,
    faQuestion,
    faDollarSign,
    faCheck,
    faAddressCard,
    faToothbrush,
    faPlayCircle
  )
}
