import * as Types from 'types'

export const objectEntriesGenericKey = <T extends string | number>(obj: object) => Object.entries(obj) as [T, any][]

export const objectKeys = <O>(o: O) => {
  return Object.keys(o) as (keyof O)[]
}

export const isJson = (str: string) => {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export const getShownPrice = (
  term: Types.PlanTerm,
  dependent: boolean,
  plan: {
    annualPrice: number | null
    annualDependentPrice: number | null
    monthlyPrice: number | null
    monthlyDependentPrice: number | null
  }
) => {
  const annualPrice = plan.annualPrice ?? 0
  const annualDependentPrice = plan.annualDependentPrice ?? 0
  const monthlyPrice = plan.monthlyPrice ?? 0
  const monthlyDependentPrice = plan.monthlyDependentPrice ?? 0

  return term === Types.PlanTerm.ANNUAL
    ? dependent
      ? annualDependentPrice
      : annualPrice
    : dependent
    ? monthlyDependentPrice
    : monthlyPrice
}
