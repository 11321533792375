/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppGlobal
// ====================================================

export interface AppGlobal_meContact_account_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
}

export interface AppGlobal_meContact_account_dentists {
  __typename: "Dentist";
  id: string;
  salutation: Salutation;
  name: string;
  degree: Degree;
}

export interface AppGlobal_meContact_account {
  __typename: "Account";
  id: string;
  code: string;
  logoUrl: string | null;
  programName: string;
  sitePhone: string;
  siteUrl: string | null;
  clearentPaymentKey: string | null;
  offices: AppGlobal_meContact_account_offices[];
  dentists: AppGlobal_meContact_account_dentists[];
  status: AccountStatus;
}

export interface AppGlobal_meContact_subscriptions_plan_discounts {
  __typename: "Discount";
  id: string;
  category: DiscountCategory;
  value: number;
}

export interface AppGlobal_meContact_subscriptions_plan_treatments {
  __typename: "Treatment";
  id: string;
  code: string;
  name: string;
  quantity: number;
  value: number;
}

export interface AppGlobal_meContact_subscriptions_plan {
  __typename: "Plan";
  id: string;
  name: string;
  additionalTerms: string | null;
  annualPrice: number | null;
  dependentDiscount: number | null;
  discounts: AppGlobal_meContact_subscriptions_plan_discounts[];
  globalDiscount: number | null;
  globalDiscountActive: boolean;
  monthlyPrice: number | null;
  showValue: boolean;
  treatments: AppGlobal_meContact_subscriptions_plan_treatments[];
}

export interface AppGlobal_meContact_subscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  creditCard_token: string | null;
  creditCard_type: string | null;
  creditCard_lastfour: string | null;
  creditCard_exp: string | null;
  creditCard_desc: string | null;
  startDate: any;
  endDate: any;
  isEnabled: boolean;
  isCancelled: boolean;
  status: PlanSubscriptionStatus;
  collectingMethod: collectingMethod;
  plan: AppGlobal_meContact_subscriptions_plan | null;
  planTerm: PlanTerm | null;
  renewalSubscriptionId: string | null;
}

export interface AppGlobal_meContact {
  __typename: "Contact";
  id: string;
  code: string;
  planTerm: PlanTerm | null;
  secure: string;
  account: AppGlobal_meContact_account;
  subscriptions: AppGlobal_meContact_subscriptions[] | null;
}

export interface AppGlobal {
  meContact: AppGlobal_meContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Billing
// ====================================================

export interface Billing_meContact {
  __typename: "Contact";
  id: string;
}

export interface Billing {
  meContact: Billing_meContact | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: myBillingHistory
// ====================================================

export interface myBillingHistory_myBillingHistory {
  __typename: "BillingHistory";
  amount: number | null;
  createdAt: string | null;
  id: string | null;
  last4: string | null;
  cardExp: string | null;
  plan: string | null;
  status: string | null;
  isDependent: boolean;
  paymentDate: string | null;
  paymentNote: string | null;
  sequenceNumber: number | null;
  collectionMethod: string | null;
  invoiceNumber: string | null;
  paymentErrorMessage: string | null;
}

export interface myBillingHistory {
  myBillingHistory: myBillingHistory_myBillingHistory[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactSubscription
// ====================================================

export interface UpdateContactSubscription_updateContactSubscription {
  __typename: "ContactPlanSubscription";
  id: string;
  isCancelled: boolean;
  isEnabled: boolean;
  autoRenewal: boolean;
  status: PlanSubscriptionStatus;
  startDate: any;
  endDate: any;
}

export interface UpdateContactSubscription {
  updateContactSubscription: UpdateContactSubscription_updateContactSubscription | null;
}

export interface UpdateContactSubscriptionVariables {
  id: string;
  cancel?: boolean | null;
  reactivate?: boolean | null;
  accept?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactPlanSubscriptionCard
// ====================================================

export interface UpdateContactPlanSubscriptionCard_updateContactPlanSubscriptionCard {
  __typename: "ContactPlanSubscription";
  id: string;
}

export interface UpdateContactPlanSubscriptionCard {
  updateContactPlanSubscriptionCard: UpdateContactPlanSubscriptionCard_updateContactPlanSubscriptionCard;
}

export interface UpdateContactPlanSubscriptionCardVariables {
  id: string;
  creditCard_token: string;
  creditCard_type: string;
  creditCard_desc: string;
  creditCard_lastfour: string;
  creditCard_exp: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetContactPlanSubscriptionsFull
// ====================================================

export interface GetContactPlanSubscriptionsFull_getContactPlanSubscriptions_plan {
  __typename: "Plan";
  id: string;
  name: string;
  annualPrice: number | null;
  annualPriceActive: boolean;
  monthlyPrice: number | null;
  monthlyPriceActive: boolean;
  status: PlanStatus;
  monthlyDependentPrice: number | null;
  annualDependentPrice: number | null;
  dependentDiscount: number | null;
}

export interface GetContactPlanSubscriptionsFull_getContactPlanSubscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  accountId: string;
  fee: number;
  startDate: any;
  endDate: any;
  planTerm: PlanTerm | null;
  status: PlanSubscriptionStatus;
  plan: GetContactPlanSubscriptionsFull_getContactPlanSubscriptions_plan | null;
}

export interface GetContactPlanSubscriptionsFull {
  getContactPlanSubscriptions: GetContactPlanSubscriptionsFull_getContactPlanSubscriptions | null;
}

export interface GetContactPlanSubscriptionsFullVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: mySubscriptions
// ====================================================

export interface mySubscriptions_mySubscriptions_contact {
  __typename: "Contact";
  secure: string;
  code: string;
  parentId: string | null;
}

export interface mySubscriptions_mySubscriptions {
  __typename: "ContactPlanSubscription";
  id: string;
  renewalSubscriptionId: string | null;
  contact: mySubscriptions_mySubscriptions_contact;
}

export interface mySubscriptions {
  mySubscriptions: mySubscriptions_mySubscriptions[];
}

export interface mySubscriptionsVariables {
  isArchived?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EnrollMemberIdentity
// ====================================================

export interface EnrollMemberIdentity {
  enrollContactIdentity: string;
}

export interface EnrollMemberIdentityVariables {
  idToken: string;
  firstName: string;
  lastName: string;
  phone: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: firstOfficeForContact
// ====================================================

export interface firstOfficeForContact_firstOfficeForContact {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
  email: string | null;
}

export interface firstOfficeForContact {
  firstOfficeForContact: firstOfficeForContact_firstOfficeForContact;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Provider
// ====================================================

export interface Provider_livePlans_treatments {
  __typename: "Treatment";
  id: string;
  quantity: number;
  name: string;
}

export interface Provider_livePlans {
  __typename: "Plan";
  id: string;
  name: string;
  minAge: number | null;
  maxAge: number | null;
  annualPriceActive: boolean;
  annualPrice: number | null;
  monthlyPriceActive: boolean;
  monthlyPrice: number | null;
  globalDiscountActive: boolean;
  globalDiscount: number | null;
  treatments: Provider_livePlans_treatments[];
}

export interface Provider {
  livePlans: Provider_livePlans[];
}

export interface ProviderVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublicGlobal
// ====================================================

export interface PublicGlobal_account_public_dentists {
  __typename: "Dentist";
  id: string;
  degree: Degree;
  name: string;
  salutation: Salutation;
}

export interface PublicGlobal_account_public_offices {
  __typename: "Office";
  id: string;
  name: string;
  address1: string;
  address2: string | null;
  city: string;
  state: State;
  zip: string;
  phone: string;
}

export interface PublicGlobal_account_public {
  __typename: "Account";
  id: string;
  name: string;
  code: string;
  programName: string;
  logoUrl: string | null;
  siteColor: SiteColor;
  siteImage: SiteImage;
  siteUrl: string | null;
  clearentPaymentKey: string | null;
  sitePhone: string;
  dentists: PublicGlobal_account_public_dentists[];
  offices: PublicGlobal_account_public_offices[];
}

export interface PublicGlobal {
  account_public: PublicGlobal_account_public | null;
}

export interface PublicGlobalVariables {
  codeOrSlug: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateContactIdentity
// ====================================================

export interface UpdateContactIdentity {
  updateContactIdentity: string | null;
}

export interface UpdateContactIdentityVariables {
  currentEmail: string;
  newEmail: string;
  phone: string;
  firstName: string;
  lastName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccountStatus {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  CLOSED = "CLOSED",
  CLOSE_PENDING = "CLOSE_PENDING",
  PENDING = "PENDING",
  SUSPENDED = "SUSPENDED",
}

export enum Degree {
  DDS = "DDS",
  DMD = "DMD",
  NONE = "NONE",
}

export enum DiscountCategory {
  ADJUNCTIVE_GENERAL_SERVICES = "ADJUNCTIVE_GENERAL_SERVICES",
  DIAGNOSTIC = "DIAGNOSTIC",
  ENDODONTICS = "ENDODONTICS",
  IMPLANT_SERVICES = "IMPLANT_SERVICES",
  MAXILLOFACIAL_PROSTHETICS = "MAXILLOFACIAL_PROSTHETICS",
  ORAL_AND_MAXILLOFACIAL_SURGERY = "ORAL_AND_MAXILLOFACIAL_SURGERY",
  ORTHODONTICS = "ORTHODONTICS",
  PERIODONTICS = "PERIODONTICS",
  PREVENTIVE = "PREVENTIVE",
  PROSTHODONTICS_FIXED = "PROSTHODONTICS_FIXED",
  PROSTHODONTICS_REMOVABLE = "PROSTHODONTICS_REMOVABLE",
  RESTORATIVE = "RESTORATIVE",
}

export enum PlanStatus {
  DRAFT = "DRAFT",
  LEGACY = "LEGACY",
  LIVE = "LIVE",
  TEMPLATE = "TEMPLATE",
}

export enum PlanSubscriptionStatus {
  ACCEPTED = "ACCEPTED",
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  CANCEL_PENDING = "CANCEL_PENDING",
  EXPIRED = "EXPIRED",
  LEGACY = "LEGACY",
  MANUAL = "MANUAL",
  PENDING = "PENDING",
  PROPOSED = "PROPOSED",
  SUSPENDED = "SUSPENDED",
}

export enum PlanTerm {
  ANNUAL = "ANNUAL",
  MONTHLY = "MONTHLY",
}

export enum Salutation {
  Dr = "Dr",
  Mr = "Mr",
  Mrs = "Mrs",
  Ms = "Ms",
}

export enum SiteColor {
  BLUE = "BLUE",
  GREEN = "GREEN",
  ORANGE = "ORANGE",
  RED = "RED",
}

export enum SiteImage {
  COUPLE = "COUPLE",
  MAN = "MAN",
  WOMAN = "WOMAN",
}

export enum State {
  AK = "AK",
  AL = "AL",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  GU = "GU",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  PR = "PR",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UM = "UM",
  UT = "UT",
  VA = "VA",
  VI = "VI",
  VT = "VT",
  WA = "WA",
  WI = "WI",
  WV = "WV",
  WY = "WY",
}

export enum collectingMethod {
  AUTO = "AUTO",
  MANUAL = "MANUAL",
}

//==============================================================
// END Enums and Input Objects
//==============================================================
