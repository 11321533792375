import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import * as Types from '../types'
import moment from 'moment'

import { useMutation } from '@apollo/react-hooks'
//import { UPDATE_CONTACT_SUBSCRIPTION, UPDATE_CONTACT_CARD } from '../graphql/_view-member-sheet'
import {
  UPDATE_CONTACT_SUBSCRIPTION, UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD, UPDATE_START_END_DATE, CHANGE_CONTACT_PLAN_SUBSCRIPTION_PLAN,
  RENEW_CONTACT_PLAN_SUBSCRIPTION, CLONE_CONTACT_PLAN_SUBSCRIPTION, RESEND_RENEWAL_EMAIL
} from '../graphql/_view-member-sheet'

import { RESEND_MEMBER_WELCOME_EMAIL } from '../graphql/_invite_fields'
// import Switch from '../switch'
import { Switch as EvergreenSwitch } from 'evergreen-ui'
import { Formik, Form } from 'formik'


import { Pane, Text, Heading, toaster, Label } from 'evergreen-ui'
import Button from '../button'
import Card from '../card'
import CardHeader from '../card-header'
import { ApolloClient } from 'apollo-client'
import { colors } from '../utility/theme'
import { getShownPrice } from '../utility/transforms'
import { memberAffectedQueries } from '../utility/member-affected-queries'

import ContractSheet from '../_sheets/contract-sheet'
import UpdatePlanSheet from '../_sheets/update-plan-sheet'
import UpdatePlanDateSheet from '../_sheets/update-plan-date-sheet'
import ViewMemberSheet from '../_sheets/view-member-sheet'
import ConfirmDialog from '../_dialogs/confirm-dialog'
import PayButton from '../pay-button'


import { capital } from 'case'
import { PlanTerm } from '../types'
import SelectPlanSheet from '../_sheets/select-plan-sheet'
import CardUpcomingRenewal from '../card-upcoming-renewal'

export type Props = {
  contact: Types.ViewMemberSheet_contact
  secureContact: Types.SecureContact
  apolloClient?: ApolloClient<any>
  isAdmin: boolean
  accountToken: any
}

const ViewMemberPlan = ({ contact, secureContact, apolloClient, accountToken, isAdmin }: Props) => {
  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)
  const [isConfirmDialogCollectionMethodShown, setIsConfirmDialogCollectionMethodShown] = useState(false)
  const [isAuthorizedDialogShown, setIsAuthorizedDialogShown] = useState(false)

  const [confirmFunction, setConfirmFunction] = useState({} as any)
  const [cancelFunction, setCancelFunction] = useState({} as any)

  const [isContractSheetShown, setIsContractSheetShown] = useState(false)
  const [isUpdatePlanShown, setIsUpdatePlanShown] = useState(false)
  const [isRenewPlanShown, setIsRenewPlanShown] = useState(false)
  const [isReactivatePlanShown, setIsReactivatePlanShown] = useState(false)
  const [isUpdatePlanDateShown, setIsUpdatePlanDateShown] = useState(false)
  const [isParentSheetShown, setIsParentSheetShown] = useState(false)

  const [isPlanReactivated, SetIsPlanReactivated] = useState(false)

  const { plan, planTerm, subscriptions } = contact


  // TODO: Need to get the subscription data from backend.
  const paymentKey = contact.account.clearentPaymentKey
  const currentSub = (subscriptions && subscriptions.length > 0) ? subscriptions[0] : null

  const [subscriptionData, setSubscriptionData] = useState({
    cancelled: currentSub?.isCancelled,
    cancelDate: currentSub?.cancelAt,
    active: currentSub?.isEnabled,
    effectiveDate: currentSub?.startDate,
    endDate: currentSub?.endDate,
    status: currentSub?.status,
    collectingMethod: currentSub?.collectingMethod,
    renewalSubscriptionId: currentSub?.renewalSubscriptionId
  })
  useEffect(() => {
    console.log('currentSub', currentSub)
    console.log('subscriptionData', subscriptionData)
  },[])

  if (!(plan && planTerm)) throw Error('Contact data incomplete')

  const [currentPlan, setCurrentPlan] = useState(plan)

  var price = getShownPrice(planTerm, !!contact.parent, currentPlan)

  const [updateSubscription, updateSubscriptionStatus] = useMutation<
    Types.UpdateContactSubscription,
    Types.UpdateContactSubscriptionVariables
  >(UPDATE_CONTACT_SUBSCRIPTION,
    {
      refetchQueries: memberAffectedQueries
    })

  useEffect(() => {
    if (updateSubscriptionStatus.error) toaster.danger('Unable to update plan')
    else if (updateSubscriptionStatus.data && !updateSubscriptionStatus.loading) {
      const sub = updateSubscriptionStatus.data.updateContactSubscription
      const updatedData = { ...subscriptionData }
      updatedData.active = sub?.isEnabled
      updatedData.cancelled = sub?.isCancelled
      updatedData.cancelDate = sub?.cancelAt
      updatedData.status = sub?.status
      setSubscriptionData(updatedData)
      if (sub?.isCancelled) {
        toaster.danger(`Plan membership has been cancelled`)
      } else {
        toaster.success(`Plan membership has been updated!`)
      }
    }
  }, [updateSubscriptionStatus])

  // For saved credit card action
  const [updatePlanSubscriptionCard, updatePlanSubscriptionCardStatus] = useMutation<
    Types.UpdateContactPlanSubscriptionCard,
    Types.UpdateContactPlanSubscriptionCardVariables
  >(UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD,
    {
      refetchQueries: ['MembersTable', 'MembersSearch']
    })


  useEffect(() => {
    if (updatePlanSubscriptionCardStatus.error) toaster.danger('Unable to update plan with credit card')
    else if (updatePlanSubscriptionCardStatus.data && !updatePlanSubscriptionCardStatus.loading) {
      toaster.success(`Credit card updated!`)
    }
  }, [updatePlanSubscriptionCardStatus])

  const [update, updateStatus] = useMutation<Types.updateStartEndDate, Types.updateStartEndDateVariables>(UPDATE_START_END_DATE,
    {
      refetchQueries: memberAffectedQueries
    })

  const [changeSubscriptionPlan, changeSubscriptionPlanStatus] = useMutation<Types.changeContactPlanSubscriptionPlan, Types.changeContactPlanSubscriptionPlanVariables>(CHANGE_CONTACT_PLAN_SUBSCRIPTION_PLAN,
    {
      refetchQueries: memberAffectedQueries
    })

  const [renewContactSubscription, renewContactSubscriptionStatus] = useMutation<Types.renewContactPlanSubscription, Types.renewContactPlanSubscriptionVariables>(RENEW_CONTACT_PLAN_SUBSCRIPTION,
    {
      refetchQueries: [...memberAffectedQueries, 'GetContactPlanSubscriptionsFull']
    })
  const [resendRenewalEmail] = useMutation<Types.resendRenewalEmail>(RESEND_RENEWAL_EMAIL, {
    fetchPolicy: "no-cache",
    onCompleted: () => {
      toaster.success(`send resend renewal email!`)
    },
    onError: () => {
      toaster.danger(`Unable to send resend renewal email!`)
    }
  })

  const [cloneContactSubscription, cloneContactSubscriptionStatus] = useMutation<Types.cloneContactPlanSubscription, Types.cloneContactPlanSubscriptionVariables>(CLONE_CONTACT_PLAN_SUBSCRIPTION,
    {
      refetchQueries: [...memberAffectedQueries, 'GetContactPlanSubscriptionsFull']
    })


  useEffect(() => {
    if (updateStatus.error) toaster.danger('Unable to update Date')
    else if (updateStatus.data && !updateStatus.loading) {
      toaster.success(`Date updated!`)
      const subsData = subscriptionData;
      subsData.effectiveDate = updateStatus.data?.updateStartEndDate.startDate;
      subsData.endDate = updateStatus.data?.updateStartEndDate.endDate;
      setSubscriptionData(subsData)
      setIsUpdatePlanDateShown(false);
    }
  }, [updateStatus])

  useEffect(() => {
    if (changeSubscriptionPlanStatus.error) toaster.danger('Unable to change plan')
    else if (changeSubscriptionPlanStatus.data && !changeSubscriptionPlanStatus.loading) {
      toaster.success(`Plan updated!`)
      const updatedPlan = changeSubscriptionPlanStatus.data?.changeContactPlanSubscriptionPlan?.plan
      if (updatedPlan) {
        setCurrentPlan(updatedPlan)
        price = getShownPrice(planTerm, !!contact.parent, currentPlan)
      }
      setIsUpdatePlanShown(false);
    }
  }, [changeSubscriptionPlanStatus])

  useEffect(() => {
    if (renewContactSubscriptionStatus.error) toaster.danger('Unable to renew subscription')
    else if (renewContactSubscriptionStatus.data && !renewContactSubscriptionStatus.loading) {
      toaster.success(`Subscription renewed!`);
      const updatedData = { ...subscriptionData }
      updatedData.renewalSubscriptionId = renewContactSubscriptionStatus.data?.renewContactPlanSubscription.id
      setSubscriptionData(updatedData)
      setIsRenewPlanShown(false);
    }
  }, [renewContactSubscriptionStatus])

  useEffect(() => {
    if (cloneContactSubscriptionStatus.error) toaster.danger('Unable to reactivate subscription')
    else if (cloneContactSubscriptionStatus.data && !cloneContactSubscriptionStatus.loading) {
      toaster.success(`Subscription reactivated!`);
      setIsReactivatePlanShown(false);
      SetIsPlanReactivated(true);
    }
  }, [cloneContactSubscriptionStatus])


  const [creditCardToken, setCreditCardToken] = useState<Types.SavedTokenInput | undefined>({
    cardType: currentSub?.creditCard_type ?? '',
    tokenId: currentSub?.creditCard_token ?? '',
    lastFourDigits: currentSub?.creditCard_lastfour ?? '',
    expDate: currentSub?.creditCard_exp ?? ''
  })

  const updateToken = (token: Types.SavedTokenInput) => {
    // Call backend to update card info.
    if (currentSub != null) {
      updatePlanSubscriptionCard({
        variables: {
          id: currentSub.id,
          creditCard_token: token.tokenId,
          creditCard_type: token.cardType,
          creditCard_lastfour: token.lastFourDigits,
          creditCard_desc: '',
          creditCard_exp: token.expDate
        }
      })
      setCreditCardToken(token)
    }
  }

  const [resendMemberWelcomeEmail, { loading, error, data }] = useMutation<Types.resendMemberWelcomeEmail, Types.resendMemberWelcomeEmailVariables>(
    RESEND_MEMBER_WELCOME_EMAIL,
    {
      client: apolloClient
    }
  )

  useEffect(() => {
    if (error) toaster.danger('Unable to resend invitation.')
    if (!loading && data) {
      toaster.success(`Invitation Resent!`)
    }
  }, [loading, error, data])

  const updatePlan = (planId: string, term: PlanTerm) => {
    if (currentSub?.id) {
      changeSubscriptionPlan({
        variables: {
          id: currentSub?.id,
          planId: planId,
          planTerm: term
        }
      })
    }
    setIsUpdatePlanShown(false)
  }

  const renewPlan = (planId: string, term: PlanTerm) => {
    if (currentSub?.id) {
      renewContactSubscription({
        variables: {
          id: currentSub?.id,
          planId: planId,
          planTerm: term,
          firstName: secureContact?.firstName,
          lastName: secureContact?.lastName,
          email: secureContact?.email,
          phoneNumber: secureContact?.phone
        }
      })
    }
  }
  const resendMemberRenewalEmail = () => {
    if (currentSub?.id) {
      resendRenewalEmail({
        variables: {
          id: currentSub?.id,
          firstName: secureContact?.firstName,
          lastName: secureContact?.lastName,
          email: secureContact?.email,
        }
      })
    }
  }

  const reactivatePlan = (planId: string, term: PlanTerm) => {
    if (currentSub?.id) {
      cloneContactSubscription({
        variables: {
          id: currentSub?.id,
          planId: planId,
          planTerm: term,
          firstName: secureContact?.firstName,
          lastName: secureContact?.lastName,
          email: secureContact?.email,
          phoneNumber: secureContact?.phone
        }
      })
    }
  }

  const isEmpty = (s: string | null | undefined): boolean => { return (s == null || s == undefined || s.length == 0) }

  const activatePlan = () => {
    if (currentSub?.collectingMethod == "AUTO" && isEmpty(creditCardToken?.tokenId)) {
      toaster.danger("Cannot activate without credit card.")
      return
    }

    updateSubscription({ variables: { id: currentSub!.id, reactivate: true } })
  }

  const showAuthorizedButton = () => subscriptionData.status === 'PROPOSED' && !subscriptionData.cancelled

  const authorizeAccept = () => {
    if (currentSub?.collectingMethod == "AUTO" && isEmpty(creditCardToken?.tokenId)) {
      toaster.danger("Cannot activate without credit card.")
      return
    }

    updateSubscription({ variables: { id: currentSub!.id, reactivate: true, accept: true } })
  }


  return (
    <>
      {contact.parent && (
        <ViewMemberSheet isAdmin={isAdmin} isShown={isParentSheetShown} setIsShown={setIsParentSheetShown} memberId={contact.parent.id}
          accountToken={accountToken} tanentType={isAdmin ? 'provider' : 'member'} />
      )}
      <ContractSheet
        isShown={isContractSheetShown}
        setIsShown={setIsContractSheetShown}
        planId={currentPlan.id}
        planTerm={planTerm}
        cancelled={subscriptionData.cancelled}
        effectiveDate={subscriptionData.effectiveDate}
        endDate={subscriptionData.endDate}
        dependent={!!contact.parent}
      />
      <UpdatePlanDateSheet
        isShown={isUpdatePlanDateShown}
        setIsShown={setIsUpdatePlanDateShown}
        subsId={currentSub?.id ?? ''}
        planName={currentPlan.name}
        memberName={`${secureContact.firstName} ${secureContact.lastName}`}
        isDependent={false}
        cancelled={subscriptionData.cancelled}
        effectiveDate={subscriptionData.effectiveDate}
        endDate={subscriptionData.endDate}
        udpateDate={[update, updateStatus]}
      />
      <UpdatePlanSheet
        isShown={isUpdatePlanShown}
        setIsShown={setIsUpdatePlanShown}
        currentPlanId={currentPlan.id}
        currentTerm={planTerm}
        updatePlan={updatePlan}
      />
      <SelectPlanSheet
        title={'Renew Plan'}
        isShown={isRenewPlanShown}
        setIsShown={setIsRenewPlanShown}
        currentPlanId={currentPlan.id}
        currentTerm={planTerm}
        currentPlanName={currentPlan.name}
        onSelectPlan={renewPlan}
      />
      <SelectPlanSheet
        title={'Reactivate Plan'}
        isShown={isReactivatePlanShown}
        setIsShown={setIsReactivatePlanShown}
        currentPlanId={currentPlan.id}
        currentTerm={planTerm}
        currentPlanName={currentPlan.name}
        onSelectPlan={reactivatePlan}
      />
      {/* Cancel Plan Confirm */}
      <ConfirmDialog
        isShown={isConfirmDialogShown}
        setIsShown={setIsConfirmDialogShown}
        confirm={confirmFunction}
        body="Are you sure you want to cancel this plan?"
        intent="danger"
      />
      <ConfirmDialog
        isShown={isConfirmDialogCollectionMethodShown}
        setIsShown={setIsConfirmDialogCollectionMethodShown}
        confirm={confirmFunction}
        cancel={cancelFunction}
        body="Are you sure you want to change collection method"
        intent="danger"
      />
      <ConfirmDialog
        isShown={isAuthorizedDialogShown}
        setIsShown={setIsAuthorizedDialogShown}
        confirm={() => authorizeAccept()}
        body="You are about to manually activate this patient’s membership enrollment. By confirming this action, the practice acknowledges a signed digital agreement may not be completed. The Practice accepts responsibility of all agreement terms by accepting enrollment on behalf of the patient."
        intent="danger"
      />

      <SheetBodyLayout>
        <Pane gridArea="body" background="blueTint" overflow="scroll">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader>
              <Heading size={500}>Plan Details</Heading>
            </CardHeader>
            {isPlanReactivated ? (<Pane padding={24}>A new subscription is created.</Pane>)
              :
              <Pane padding={16}>
                <Pane padding={8} display="flex" flexDirection="column">
                  <Text overflowWarp="break-word" wordWrap="break-word" size={500} marginBottom={8}>
                    <strong>Plan: </strong>
                    {currentPlan.name}{' '}
                    <Text size={500} color="muted">
                      ({capital(currentPlan.status)})
                    </Text>
                  </Text>
                  <Text size={500} marginBottom={8}>
                    <strong>Member Status: </strong>
                    {subscriptionData?.status}
                  </Text>
                  <Text size={500} marginBottom={8}>
                    <strong>Billing Term: </strong>
                    {capital(planTerm ?? '')}
                  </Text>
                  <Text size={500}>
                    <strong>Price: </strong> ${price} / {planTerm === Types.PlanTerm.ANNUAL ? 'year' : 'month'}
                  </Text><br />
                  <Pane display="flex" justifyContent="flex-start">
                    {
                      subscriptionData?.status == 'CANCELLED' ? (
                        <Button marginLeft={0} onClick={() => setIsUpdatePlanShown(true)}>
                          Change Plan
                        </Button>
                      ) : <></>
                    }
                    <Button marginLeft={8}
                      isLoading={loading && !data}
                      iconBefore={['fas', 'paper-plane']}
                      onClick={() => resendMemberWelcomeEmail({ variables: { email: secureContact.email ?? '' } })}
                    >
                      Resend Invitation
                    </Button>
                    {(showAuthorizedButton()) ? (
                      <Button marginLeft={0}
                        onClick={() => setIsAuthorizedDialogShown(true)}
                      >
                        Authorized
                      </Button>
                    ) : (<></>)
                    }
                  </Pane>

                  <Pane
                    height={1}
                    marginY={16}
                    width={48}
                    alignSelf="center"
                    backgroundColor={colors.border.default}
                  ></Pane>

                  <Text size={500} marginBottom={8}>
                    <strong>Effective Dates: </strong>
                    {moment.utc(subscriptionData.effectiveDate).format('M/D/YYYY')} -{' '}
                    {moment.utc(subscriptionData.endDate).format('M/D/YYYY')}
                  </Text>
                  <Text size={500} marginBottom={8}>
                    <strong>Renewal: </strong>
                    <Text size={500} color={subscriptionData.cancelled ? colors.red.base : undefined}>
                      {/* {subscriptionData.cancelled ? `Cancelled` : 'Annual'} */}
                      {subscriptionData.cancelled ? `Cancelled (${subscriptionData.cancelDate ? moment.utc(subscriptionData.cancelDate).format('M/D/YYYY') : 'Not found'})` : 'Annual'}
                    </Text>
                  </Text>
                  <Text size={500} marginBottom={8}>
                    <strong>{subscriptionData.cancelled ? 'Termination' : 'Renewal'} Date: </strong>{' '}
                    {moment.utc(subscriptionData.endDate).add(1, "days").format('M/D/YYYY')}
                  </Text>
                </Pane>
                <Pane display="flex" justifyContent="flex-start">
                </Pane>

                <Pane display="flex" justifyContent="flex-start">
                  <Button marginRight={8} onClick={() => setIsContractSheetShown(true)}>
                    View Contract
                  </Button>
                  {(subscriptionData.status === 'PENDING' && !subscriptionData.cancelled) ? (
                    <Button marginRight={8}
                      isLoading={updateSubscriptionStatus.loading}
                      intent="success"
                      onClick={() => activatePlan()}
                    >
                      Activate
                    </Button>
                  ) : (<></>)}

                  {subscriptionData.cancelled ? (
                    <Button
                      isLoading={updateSubscriptionStatus.loading}
                      intent="success" onClick={() => setIsReactivatePlanShown(true)}
                    >
                      Reactivate
                    </Button>
                  ) : (
                    <Button
                      isLoading={updateSubscriptionStatus.loading}
                      intent="danger"
                      onClick={() => {
                        setConfirmFunction(() => () =>
                          updateSubscription({ variables: { id: currentSub!.id, cancel: true } })
                        )
                        setIsConfirmDialogShown(true)
                      }}
                    >
                      Cancel
                    </Button>
                  )
                  }
                  <Button marginLeft={8} onClick={() => setIsUpdatePlanDateShown(true)}>
                    Change Date
                  </Button>
                </Pane>
              </Pane>
            }
          </Card>

          <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Upcoming Renewal</Heading>
            </CardHeader>
            {subscriptionData?.status == 'ACTIVE' ?
              <Pane display="flex" padding={16} justifyContent="flex-start" flexDirection="column">
                {
                  !(subscriptionData?.renewalSubscriptionId) ? (
                    <Button marginLeft={0} onClick={() => setIsRenewPlanShown(true)}>
                      Renew Plan
                    </Button>
                  ) : (<CardUpcomingRenewal renewalSubscriptionId={subscriptionData.renewalSubscriptionId} apolloClient={apolloClient} dependent={false} isAdmin={isAdmin} setIsRenewPlanShown={setIsRenewPlanShown} resendRenewalEmail={resendMemberRenewalEmail}></CardUpcomingRenewal>
                  )
                }
              </Pane> : <></>
            }
          </Card>
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Collection Method</Heading>
            </CardHeader>
            <Pane display="flex" padding={16} justifyContent="flex-start">
              <Formik initialValues={{
                isManual: subscriptionData.collectingMethod == Types.collectingMethod.MANUAL
              }}
                onSubmit={fields => {
                  console.log(fields)
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Label alignSelf="flex-start" marginRight={8}>
                      Collect payment offline/off PFH portal
                    </Label>
                    <EvergreenSwitch name="isManual"
                      checked={values.isManual}
                      onChange={() => {
                        if (values.isManual && isEmpty(creditCardToken?.tokenId)) {
                          toaster.danger("Credit card is missing.")
                          return
                        }

                        setConfirmFunction(() => () => {
                          const newValue = !values.isManual
                          setFieldValue("isManual", newValue)
                          updateSubscription({ variables: { id: currentSub!.id, isManual: newValue } })
                        })
                        setCancelFunction(() => () => {
                          console.log(values.isManual);
                        })
                        setIsConfirmDialogCollectionMethodShown(true)
                      }}
                      height={24} marginBottom={0} />
                  </Form>
                )}
              </Formik>
            </Pane>
          </Card>
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} overflow="hidden" marginBottom={16}>
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Setup Payment</Heading>
            </CardHeader>
            <PayButton id="view-member-plan-pay" paymentKey={paymentKey ?? ''} callBack={updateToken}
              creditCardToken={creditCardToken} />
          </Card>
        </Pane>
      </SheetBodyLayout>
    </>
  )
}

export default ViewMemberPlan

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`
