import React from 'react'
import styled from 'styled-components/macro'
import moment from 'moment'

import { useApolloClient } from '@apollo/react-hooks'
import * as Types from '../types'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { Pane, Heading } from 'evergreen-ui'
import Button from '../button'
import PlanCardListNew from '../plan-card-list-new'
import { getShownPrice } from '../utility/transforms'

export type Props = {
  enrollmentState: Types.EnrollmentState
  updateEnrollmentState: (updatedState: Types.EnrollmentState) => void
  prev: () => void
  next: () => void
  parentId?: string
}

const EnrollMemberPlan = ({ enrollmentState, updateEnrollmentState, prev, next, parentId }: Props) => {
  const apolloClient = useApolloClient()
  const detailsState = enrollmentState.details
  const planState = enrollmentState.plan

  const selectPlan = (
    plan: {
      id: string
      name: string
      annualPrice: number | null
      annualDependentPrice: number | null
      monthlyPrice: number | null
      monthlyDependentPrice: number | null
    },
    term: Types.PlanTerm,

  ) => {
    updateEnrollmentState({
      plan: {
        id: plan.id,
        name: plan.name,
        term,
        price: getShownPrice(term, !!parentId, plan)
      },
      enforce_credit: !!parentId // Allow creating member without creditcard information for Provider
    })
    next()
  }

  return (
    <Formik initialValues={{}} onSubmit={() => next()} validationSchema={Yup.object({})}>
      <Form style={{ height: '100%', width: '100%' }}>
        <SheetBodyLayout>
          <Pane
            gridArea="body"
            overflow="hidden"
            display="flex"
            flexDirection="column"
            background="blueTint"
            paddingTop={0}
          >
            <Heading size={500} flexShrink={0} textAlign="center" marginBottom={8}>
              Select Plan
            </Heading>
            <PlanCardListNew
              selectPlan={selectPlan}
              selectedPlanId={planState?.id}
              selectedTerm={planState?.term}
              age={moment().diff(
                moment(`${detailsState?.dobYear}-${detailsState?.dobMonth}-${detailsState?.dobDay}`),
                'years'
              )}
              perio={!!enrollmentState.details?.perio}
              dependent={!!parentId}
              apolloClient={apolloClient}
              width="100%"
            />
          </Pane>
          <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
            <Button
              appearance="minimal"
              height={48}
              justifyContent="center"
              iconBefore={['far', 'chevron-left']}
              onClick={prev}
            >
              Back
            </Button>
            <Button
              disabled={!planState?.id}
              type="submit"
              appearance="primary"
              height={48}
              justifyContent="center"
              iconAfter={['far', 'chevron-right']}
              onClick={next}
            >
              Next
            </Button>
          </Pane>
        </SheetBodyLayout>
      </Form>
    </Formik>
  )
}

export default EnrollMemberPlan

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`
