export const clearentPayButton = (paymentKey: String, payContainerId: String, buttonText: String) => {
  // @ts-ignore
  if (window.ClearentInit) { // this is when using with Gatsby, initialization cannot be done by the script loading.
    // @ts-ignore
    window.ClearentInit()
  }

  // @ts-ignore
  const windowClearent = window.Clearent;
  if (windowClearent) {
    windowClearent.payButtonCreated = !1;
    windowClearent.reCaptchaScriptLoaded = false;
    // @ts-ignore
    windowClearent.setProperty("pay-button-parent", document.getElementById(payContainerId));
    windowClearent.payButton({
      "pk": paymentKey,
      "success-message": "Credit card is registered."
    });
  }

  // @ts-ignore
  document.querySelector('#Clearent-pay-now').innerHTML = buttonText;
  // @ts-ignore
  document.querySelector('#Clearent-pay-now').setAttribute('style', 'background-color: white; background-image: linear-gradient(to bottom, #FFFFFF, #F4F5F7); border: none; outline: none; box-shadow: inset 0 0 0 1px rgba(67, 90, 111, 0.14), inset 0 -1px 1px 0 rgba(67, 90, 111, 0.06); cursor: pointer; text-decoration: none; font-size: 14px; color: #425A70;');
}

export const changePayButtonText = (buttonText: String) => {
  // @ts-ignore
  document.querySelector('#Clearent-pay-now').innerHTML = buttonText;
}

interface ClearentCallback {
  (success: boolean, response: object): void
}

export const clearentAddPayButtonListener = (callback: ClearentCallback) => {
  // @ts-ignore
  window.AddClearentListener(callback)
}

export const clearentRemovePayButtonListener = () => {
  // @ts-ignore
  window.RemoveClearentListener(null)
}

export const saveClearentCard = (responseJson: object) => {
  let clearentTokenId = '';
  let clearentCardType = '';
  let lastFour = '';
  let expDate = '';

  // @ts-ignore
  const { payload } = responseJson;

  if (payload && payload.tokenResponse) {
    clearentTokenId = payload.tokenResponse['token-id'];
    clearentCardType = payload.tokenResponse['card-type'];
    expDate = payload.tokenResponse['exp-date'];
    lastFour = payload.tokenResponse['last-four-digits']
  }

  return {
    clearentCardType,
    clearentTokenId,
    expDate,
    lastFour
  }
}

export const setClearentPayButtonArea = (cardType: string, payTokenId: string, payContainerId: string) => {
  const payContainer = document.getElementById(payContainerId);

  let payButtonText = '';
  if (payTokenId && payTokenId !== null) {
    payButtonText = 'Change';
    const lastTokens = payTokenId.substr(payTokenId.length - 4);
    if (payContainer) {
      const cardInfoElId = `${payContainerId}-card-info`
      const cardInfoNode = document.getElementById(cardInfoElId);
      if (cardInfoNode && payContainer.contains(cardInfoNode)) {
        cardInfoNode.innerHTML = `${cardType} ending in ${lastTokens}`;
      } else {
        const cardInfoElement = document.createElement('span');
        cardInfoElement.setAttribute('id', cardInfoElId);
        cardInfoElement.innerHTML = `${cardType} ending in ${lastTokens}`;
        payContainer.prepend(cardInfoElement);
      }

      payContainer.setAttribute('style', 'display: flex; justify-content: space-between; align-items: center;');
    }
  } else {
    if (payContainer) {
      payContainer.setAttribute('style', 'display: flex; justify-content: flex-end');
    }
    payButtonText = 'Add';
  }

  return payButtonText;
}