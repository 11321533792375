import React from 'react'
import { GlobalStyle } from 'styles'
import { MediaQueryProvider } from '@pearly/lib'

import 'normalize.css'
import '@fortawesome/fontawesome-svg-core/styles.css'

import { config as fontAwesomeConfig } from '@fortawesome/fontawesome-svg-core'
import { initializeIconLibrary } from 'utility/icon-library'

fontAwesomeConfig.autoAddCss = false
initializeIconLibrary()

export type Props = {
  children: React.ReactNode
}

const Layout = ({ children }: Props) => (
  <>
    <GlobalStyle />
    <MediaQueryProvider>{children}</MediaQueryProvider>
  </>
)

export default Layout
