import React, { useEffect, useState } from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'
import { ENROLL_MEMBER_IDENTITY } from '../graphql/_enroll-member'
import { ENROLL_MEMBER, ENROLL_DEPENDENT, ENROLL_DEPENDENT_PUBLIC } from '../graphql/secure-operations'

import { Card, Heading, Pane, toaster } from 'evergreen-ui'
import Button from '../button'
import MemberPaymentFields from '../_fields/member-payment-fields'

import { signupMember } from '../utility/auth'
import { memberAffectedQueries } from '../utility/member-affected-queries'

import { VgsWrap } from '../utility/vgs-utils'
import { useGlobalCreditCardEngineHack } from '../credit-card-provider'
import ConfirmDialog from '../_dialogs/confirm-dialog'
import { Label } from 'evergreen-ui'
import { Switch as EvergreenSwitch } from 'evergreen-ui'
import { Formik, Form } from 'formik'
import CardHeader from '../card-header'

export type Props = {
  enrollmentState: Types.EnrollmentState
  prev: () => void
  next: () => void
  parentId?: string
  memberApp?: boolean
}

const EnrollMemberPayment = ({ enrollmentState, prev, next, parentId, memberApp }: Props) => {
  const [isConfirmDialogCollectionMethodShown, setIsConfirmDialogCollectionMethodShown] = useState(false)
  const [confirmFunction, setConfirmFunction] = useState({} as any)
  const [cancelFunction, setCancelFunction] = useState({} as any)
  const [isManual, setIsManual] = useState(false)

  const { start, details, plan, enforce_credit } = enrollmentState
  const [loading, setLoading] = useState(false)
  // const [couponCodeState, setCouponCodeState] = useState<string | undefined>()
  const [creditCardToken, setCreditCardToken] = useState<Types.SavedTokenInput | undefined>()

  const [enrollMemberIdentity] = useMutation<Types.EnrollMemberIdentity, Types.EnrollMemberIdentityVariables>(
    ENROLL_MEMBER_IDENTITY
  )

  const [enrollMember, enrollNewMemberStatus] = useMutation<Types.EnrollMember, Types.EnrollNewMemberVariables>(
    ENROLL_MEMBER,
    {
      context: {
        secure: true
      },
      refetchQueries: memberAffectedQueries
    }
  )

  // Use a provider element to provide the credit card engine info. 
  const creditCardEngine = useGlobalCreditCardEngineHack()
  const paymentKey = creditCardEngine?.paymentKey

  useEffect(() => {
    // Error already caught in try/catch on enroll method
    if (!enrollNewMemberStatus.loading && enrollNewMemberStatus.data) {
      toaster.success(`Member successfully enrolled`)
      next()
    }
  }, [enrollNewMemberStatus, next])

  const [enrollDependent, enrollDependentStatus] = useMutation<Types.EnrollDependent, Types.EnrollDependentVariables>(
    process.env.REACT_PROVIDER_URL ? ENROLL_DEPENDENT : ENROLL_DEPENDENT_PUBLIC,
    {
      context: {
        secure: true
      },
      refetchQueries: memberAffectedQueries
    }
  )

  useEffect(() => {
    // Error already caught in try/catch on enroll method
    if (!enrollDependentStatus.loading && enrollDependentStatus.data) {
      toaster.success(`Dependent successfully enrolled`)
      next()
    }
  }, [enrollDependentStatus, next])

  const submitPayment = async () => {
    // Propogate any changes to enroll.tsx
    
    try {
      setLoading(true)
      if (!parentId) {
        if (!start || !details || !plan) throw Error('Incomplete enrollmentState')
        if (enforce_credit) {
          if (!creditCardToken) throw Error('Credit card must be provided')
        }

        const idpMember = await signupMember(start.email)
        const idToken = await idpMember.user?.getIdToken()
        if (!idToken) throw Error('Unable to retrieve ID Token')

        try {
          // Standard
          const memberIdentityOptions = {
            variables: {
              idToken,
              firstName: details.firstName,
              lastName: details.lastName,
              phone: details.phone
            }
          }

          await enrollMemberIdentity(memberIdentityOptions)
          //const contact: any =
          await enrollMember({
            variables: {
              idToken,
              planId: plan.id,
              planTerm: plan.term,
              secure: VgsWrap<Types.SecureContact>({ email: start.email, ...details }),
              // coupon: couponCodeState,
              creditCardToken: creditCardToken ?? undefined,
              isManual: isManual,
              initialStatus: enrollmentState.initialStatus ?? undefined
            }
          })
        } catch (err) {
          await idpMember.user?.delete()
          throw err
        }

      } else {
        if (!details || !plan) throw Error('Incomplete enrollmentState')
        await enrollDependent({
          variables: {
            idToken: '',
            contactId: parentId,
            planId: plan.id,
            planTerm: plan.term,
            secure: VgsWrap<Types.SecureContact>({ ...details }),
            isManual: isManual,
            // coupon: couponCodeState,
            creditCardToken: creditCardToken ?? undefined
          }
        })
      }

    } catch (err) {
      setLoading(false)
      toaster.danger(err.message.replace('GraphQL error: ', ''))
    }
  }

  const isEmpty=(s:string|null|undefined): boolean => {return (s ==null || s==undefined || s.length==0) }

  return (
    <SheetBodyLayout>
      <ConfirmDialog
        isShown={isConfirmDialogCollectionMethodShown}
        setIsShown={setIsConfirmDialogCollectionMethodShown}
        confirm={confirmFunction}
        cancel={cancelFunction}
        body="Are you sure you want to change collection method"
        intent="danger"
      />
      <Pane gridArea="body" background="blueTint" overflow="scroll">
        
        { memberApp?<></>:
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Collection Method</Heading>
            </CardHeader>
            <Pane display="flex" padding={16} justifyContent="flex-start">
              <Formik initialValues={{
                isManual: isManual
              }}
                onSubmit={fields => {
                  console.log(fields)
                }}
              >
                {({ values, setFieldValue }) => (
                  <Form>
                    <Label alignSelf="flex-start" marginRight={8}>
                      Collect payment offline/off PFH portal
                                      </Label>
                    <EvergreenSwitch name="isManual"
                      checked={values.isManual}
                      onChange={() => {
                        if(values.isManual && isEmpty(creditCardToken?.tokenId)) {
                          toaster.danger("Credit card is missing.")
                          return
                        } 

                        setConfirmFunction(() => () => {
                          setFieldValue("isManual", !values.isManual)
                          setIsManual(!values.isManual)
                          console.log(isManual)
                        })
                        setCancelFunction(() => () => {
                          console.log(values.isManual);
                        })
                        setIsConfirmDialogCollectionMethodShown(true)
                      }}
                      height={24} marginBottom={0} />
                  </Form>
                )}
              </Formik>
            </Pane>
          </Card> 
        }

        <Pane marginTop={4} marginX={16}>
          <MemberPaymentFields
            plan={plan!}
            // setCouponCodeState={setCouponCodeState}
            setCreditCardToken={setCreditCardToken}
            parentId={parentId}
            dependentName={`${details?.firstName} ${details?.lastName}`}
            paymentKey={paymentKey}
          />
        </Pane>
      </Pane>
      <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="space-between">
        <Button
          disabled={loading}
          appearance="minimal"
          height={48}
          justifyContent="center"
          iconBefore={['far', 'chevron-left']}
          onClick={prev}
        >
          Back
        </Button>
        <Button
          isLoading={loading}
          onClick={() => submitPayment()}
          appearance="primary"
          height={48}
          justifyContent="center"
          iconAfter={['far', 'chevron-right']}
        >
          {loading ? 'Enrolling' : 'Complete'}
        </Button>
      </Pane>
    </SheetBodyLayout>
  )
}

export default EnrollMemberPayment

const SheetBodyLayout = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`
