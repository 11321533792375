import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'

import { useMutation } from '@apollo/react-hooks'

import { UPDATE_SERVICE_SUBSCRIPTION_CARD } from './graphql/_service-agreement'
//import { SERVICE_NEW_PAYMENT } from './graphql/_clearent-payment'

import { Pane, Text, Heading, toaster } from 'evergreen-ui'
import Card from './card'
import CardHeader from './card-header'
import PayButton from './pay-button'
import Button from './button'

import * as Types from './types'

import moment from 'moment'

export type Props = {
  serviceSubscription: any,
  showServiceContract: () => void,
  setIsConfirmDeleteDialogShown: any
  setIsConfirmCancelDialogShown: any
  isLoading: any
  isCancelLoading: any
  isSuperAdmin: any
}

const ServiceSubscriptionCard = ({ serviceSubscription, showServiceContract, setIsConfirmDeleteDialogShown, setIsConfirmCancelDialogShown, isCancelLoading, isLoading, isSuperAdmin }: Props) => {

  const subscriptions = serviceSubscription
  const paymentKey = serviceSubscription.account.clearentPaymentKey
  const currentSub = (subscriptions !== null) ? subscriptions : null

  const [updateServiceSubscriptionCard, updateServiceSubscriptionCardStatus] = useMutation<
    Types.UpdateServicePlanSubscriptionCard,
    Types.UpdateServicePlanSubscriptionCardVariables
  >(UPDATE_SERVICE_SUBSCRIPTION_CARD)

  useEffect(() => {
    if (updateServiceSubscriptionCardStatus.error) toaster.danger('Unable to update plan with credit card')
    else if (updateServiceSubscriptionCardStatus.data && !updateServiceSubscriptionCardStatus.loading) {
      toaster.success(`Credit card updated!`)
    }
  }, [updateServiceSubscriptionCardStatus])


  const [creditCardToken, setCreditCardToken] = useState<Types.SavedTokenInput | undefined>({
    cardType: currentSub?.creditCard_type ?? '',
    tokenId: currentSub?.creditCard_token ?? '',
    lastFourDigits: currentSub?.creditCard_lastfour ?? '',
    expDate: currentSub?.creditCard_exp ?? ''
  })

  //const [newServicePayment, setNewServicePayment] = useMutation<Types.NewServicePayment, Types.NewServicePaymentVariables>(SERVICE_NEW_PAYMENT)
  /*
    useEffect(() => {
      if (setNewServicePayment.error) toaster.danger('Unable to pay')
      else if (setNewServicePayment.data && !setNewServicePayment.loading) {
        toaster.success(`Payment successfully done!`)
      }
    }, [setNewServicePayment]) */

  const updateToken = (token: Types.SavedTokenInput) => {
    // Call backend to update card info.
    if (currentSub != null) {
      updateServiceSubscriptionCard({
        variables: {
          id: currentSub.id,
          creditCard_token: token.tokenId,
          creditCard_type: token.cardType,
          creditCard_lastfour: token.lastFourDigits,
          creditCard_desc: '',
          creditCard_exp: token.expDate
        }
      })
      setCreditCardToken(token)

      /*
      if(token.lastFourDigits !== ''){
        newServicePayment({
          variables:{
            type: Types.paymentType.SUBS,
            subscriptionId: serviceSubscription.id,
            accountId: serviceSubscription.account.id,
            subscriberId: serviceSubscription.id,
            creditCard_token: token.tokenId,
            creditCard_type: token.cardType,
            creditCard_lastfour: token.lastFourDigits,
            creditCard_message: "success",
            creditCard_transStatus: "COMPLETE",
            creditCard_exp: token.expDate,
            paymentAmount: serviceSubscription.fee,
            paymentStatus: Types.paymentStatus.COMPLETE
          }
        })
      }*/
      // }else{
      //   newServicePayment({
      //     variables:{
      //       type: Types.paymentType.SUBS,
      //       subscriptionId: serviceSubscription.id,
      //       accountId: serviceSubscription.account.id,
      //       subscriberId: serviceSubscription.id,
      //       creditCard_token: token.tokenId,
      //       creditCard_type: token.cardType,
      //       creditCard_lastfour: token.lastFourDigits,
      //       creditCard_message: "error-message",
      //       creditCard_transStatus: "FAILED",
      //       creditCard_exp: token.expDate,
      //       paymentAmount: serviceSubscription.fee,
      //       paymentStatus: Types.paymentStatus.FAILED
      //     }
      //   })
      // }
    }
  }

  //const [isReceiptSheetShown, setIsReceiptSheetShown] = useState(false)
  console.log('serviceSubscription', serviceSubscription)
  return (
    <>
      <SheetBodyLayout>
        <Pane gridArea="body" background="blueTint" overflow="scroll">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader>
              <Heading size={500}>Service Plan Details</Heading>
            </CardHeader>
            <Pane padding={16}>
              <Pane padding={8} display="flex" flexDirection="column">
                <Text size={500} marginBottom={8}>
                  <strong>Name: </strong>
                  {serviceSubscription?.plan ? serviceSubscription?.plan?.name : ''}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Start Date: </strong>
                  {serviceSubscription?.startDate && moment.utc(serviceSubscription?.startDate).format('M/D/YYYY')}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>End Date: </strong>
                  {serviceSubscription?.endDate && moment.utc(serviceSubscription?.endDate).format('M/D/YYYY')}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>First Billing Date: </strong>
                  {serviceSubscription?.firstBilling && moment.utc(serviceSubscription?.firstBilling).format('M/D/YYYY')}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Service Fee (Monthly):</strong>
                  {serviceSubscription?.fee && `$${serviceSubscription?.fee}`}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Setup Fee (One time):</strong>
                  {serviceSubscription?.setupFee && `$${serviceSubscription?.setupFee}`}
                </Text>
                {serviceSubscription?.isCancelled && <Text size={500} marginBottom={8}>
                  <strong>Canceled Date: </strong>
                  {moment.utc(serviceSubscription?.cancelAt).format('M/D/YYYY')}
                </Text>}
                <Text size={500} marginBottom={8}>
                  <strong>Status: </strong>
                  {serviceSubscription?.status}
                </Text>
              </Pane>
            </Pane>
            <Pane display="flex" justifyContent="flex-start" paddingLeft={8} paddingBottom={8}>
              <Button onClick={() => serviceSubscription.status === 'PENDING' ? showServiceContract() : serviceSubscription?.agreementURL ? window.open(serviceSubscription?.agreementURL, "_blank") : ''}>
                View Contract
              </Button>
              <Button
                hidden={!isSuperAdmin}
                disabled={!isSuperAdmin}
                onClick={() => setIsConfirmDeleteDialogShown()}
                marginLeft={8} intent="danger"
                isLoading={isLoading}>
                Delete Contract
              </Button>
              <Button
                disabled={serviceSubscription.status !== Types.PlanSubscriptionStatus.ACTIVE}
                onClick={() => setIsConfirmCancelDialogShown()}
                marginLeft={8} intent="danger"
                isLoading={isCancelLoading}>
                Cancel Subscription
              </Button>
              {/*
              <Button display="none" marginLeft={8} intent="success" onClick={() => setIsReceiptSheetShown(true)}>
                Print Receipt
                </Button> */}

            </Pane>
          </Card>
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} overflow="hidden" marginBottom={16}>
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Billing Details</Heading>
            </CardHeader>
            <PayButton id="view-member-plan-pay" paymentKey={paymentKey ?? ''} callBack={updateToken} creditCardToken={creditCardToken} />
          </Card>
        </Pane>
      </SheetBodyLayout>

    </>
  )
}

export default ServiceSubscriptionCard

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`