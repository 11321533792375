import React, { createContext, useContext } from 'react'

export interface CreditCardEngine  {
  engine?: string
  url?: string
  paymentKey?: string
}

const CreditCardContext = createContext<CreditCardEngine| undefined>(undefined)
export const useCreditCardEngine = () => useContext(CreditCardContext)

export const useGlobalCreditCardEngineHack = () =>  {
   // @ts-ignore 
  return window.CreditCardEngineGlobalHack
}

export const CreditCardProvider = ({engine, children }: { engine: CreditCardEngine, children: React.ReactNode }) => {
  // @ts-ignore
  window.CreditCardEngineGlobalHack = engine // This is a hack to make it work across package boundary.
  return <CreditCardContext.Provider value={ engine }>{children}</CreditCardContext.Provider>
}
