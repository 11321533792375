import React from 'react'

import { Button, ButtonProps } from 'evergreen-ui'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends ButtonProps {
  icon: IconProp
}

const IconButton = ({ icon, ...props }: Props) => (
  <Button {...props} display="flex" justifyContent="center" paddingLeft={12} paddingRight={props.isLoading ? 0 : 12}>
    {!props.isLoading && <FontAwesomeIcon icon={icon} />}
  </Button>
)

export default IconButton
