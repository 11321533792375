import React from 'react'
import * as Types from '../types'
import styled from 'styled-components/macro'

import { Pane, Paragraph, Heading } from 'evergreen-ui'
import Card from '../card'
import Button from '../button'
import CardHeader from '../card-header'
import Icon from '../icon'

export type Props = {
  enrollmentState: Types.EnrollmentState
  next: () => void
  restart: () => void
  parentId?: string
}

const EnrollMemberComplete = ({ enrollmentState, restart, next, parentId }: Props) => (
  <SheetBodyLayout>
    <Pane gridArea="body" background="blueTint" overflow="scroll">
      <Card padding={0} elevation={0} margin={16} marginTop={4}>
        <CardHeader>
          <Heading size={500} flex={1} textAlign="center">
            Success
          </Heading>
        </CardHeader>
        <Pane padding={24} display="flex" flexDirection="column" alignItems="center">
          <Icon icon={['fad', 'check']} color="success" size="3x" marginBottom={16} />
          <Paragraph size={500} textAlign="center" marginBottom={24}>
            You have added {enrollmentState.details?.firstName} {enrollmentState.details?.lastName} to your membership
            program. {enrollmentState.details?.firstName} will receive{' '}
            {parentId
              ? 'a welcome email informing them of their enrollment.'
              : 'an email to login and set their password.'}
          </Paragraph>
          <Button height={48} iconBefore={['fas', 'user-plus']} onClick={restart}>
            Enroll Another {parentId ? 'Dependent' : 'Member'}
          </Button>
        </Pane>
      </Card>
    </Pane>
    <Pane gridArea="footer" elevation={0} padding={16} display="flex" justifyContent="flex-end">
      <Button
        appearance="primary"
        height={48}
        justifyContent="center"
        iconAfter={['far', 'chevron-right']}
        onClick={next}
      >
        Close
      </Button>
    </Pane>
  </SheetBodyLayout>
)

export default EnrollMemberComplete

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-areas:
    'body'
    'footer';
  grid-template-rows: 1fr auto;
`
