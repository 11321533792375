import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  a {
    text-decoration: none;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
      /*Tabs Styling*/
  nav a:active [aria-current="page"],
[aria-selected="true"] {
    /*colorcode for tabs*/
    background-color: #306584 !important;
    color: white !important;
    border-radius: 20px !important;
}

nav a:hover span:hover [role="tab"] {
    border-radius: 20px !important;
}

[role="tab"] {
    border-radius: 20px !important;
    box-shadow: none !important;
}

.loginButton {
  border-radius: 20px !important;
  background-color: #306584 !important;
  width: 100%;
  color: white;
  height: 40px;
  font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}


@media print
{    
  

  
    .no-print, .no-print *
    {
        display: none !important;
    }
    .SideSheet{
        width: 100% !important; 
    }

    .📦ovflw-y_scroll {
        overflow-y: hidden !important;
    }

    .📦ovflw-x_scroll {
        overflow-x: hidden !important;
    }

    .📦grd-ara_3k6n3y {
      display: none !important;
    }

    .📦bs_kymczs{
        box-shadow: none !important;
    }
    .📦grd-ara_3k6n3y{
      display: none !important;
    }
    .📦w_733px {
          width: 100% !important;
      }
}

.text-right{
  text-align: right !important;
}

`
