import React from 'react'

import { Pane, Text } from 'evergreen-ui'

export type Props = {
    contractInfo: any
}


const ServiceExhibitA = ({ contractInfo }: Props) => {

    return (
        <Pane>
            <Text>
                <p>
                    Capitalized terms used but not defined in this Exhibit A have the meaning
                    given to those terms in the Agreement.
                </p>
                <ol type="1">
                    <li>
                        {contractInfo.customerName}, a {contractInfo.stateOfOrganization} {contractInfo.entityType} with offices
                        located at {contractInfo.address} ("Customer")
                    </li>
                    <li>
                        DESCRIPTION OF SERVICES:
                    </li>
                    <ol type="a">
                        <li>
                            Platform Module –            <strong>Plan For Health Oral Wellness Plan</strong>
                        </li>
                        <ol type="i">
                            <li>
                                “Getting To Know You" Consultation
                            </li>
                            <li>
                                UCR Analysis Report And Plan Pricing Strategy
                            </li>
                            <li>
                                PFH Membership Software Training and Support
                            </li>
                            <li>
                                PMS Best Practices Training And Support
                            </li>
                            <li>
                                Digital Marketing Materials to help promote your program
                            </li>
                            <li>
                                500 Print Materials for in-office marketing
                            </li>
                            <li>
                                Follow Up And Benchmark Coaching
                            </li>
                        </ol>
                    </ol>
                    <li>
                        <strong></strong>
                        <strong>FEES: </strong>
                        <p>
                            Setup Fees: ${contractInfo.setupFee} (one-time)
                        </p>
                        <p>
                            Platform Fees: ${contractInfo.serviceFee} (monthly)
                        </p>
                        <p>
                            Renewal: Automatic monthly renewal unless terminated at least 10 days prior
                            to commencement of renewal period.
                        </p>

                    </li>
                </ol>
            </Text>
        </Pane>
    )
}

export default ServiceExhibitA