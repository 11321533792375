import React from 'react'
import { useField } from 'formik'

import { TextInput as EvergreenTextInput, TextInputProps, Pane } from 'evergreen-ui'

import Icon from './icon'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends TextInputProps {
    name: string
    icon?: IconProp
    height?: number
    half?: 'top' | 'bottom' | 'mid'
}

const TripleTextInput = ({ icon, half, width, height = 48, ...props }: Props) => {
    const [field, meta] = useField(props.name)
    if (icon) props.paddingLeft = height

    return (
        <>
            <Pane position="relative" width={width}>
                {icon && (
                    <Icon
                        icon={icon}
                        position="absolute"
                        zIndex={3}
                        size="lg"
                        lineHeight={`${height}px`}
                        left={`${height && height / 3}px`}
                    />
                )}

                <EvergreenTextInput
                    marginBottom={half === 'top' || half === 'mid' ? -0.5 : 16}
                    height={height}
                    width="100%"
                    {...field}
                    {...props}
                    isInvalid={meta.touched && !!meta.error}
                    position="relative"
                    zIndex={meta.touched && !!meta.error ? 1 : 0}
                    marginTop={half === 'bottom' ? -0.5 : 0}
                    borderTopRightRadius={half === 'bottom' || half === 'mid' ? 0 : 4}
                    borderTopLeftRadius={half === 'bottom' || half === 'mid' ? 0 : 4}
                    borderBottomRightRadius={half === 'top' || half === 'mid' ? 0 : 4}
                    borderBottomLeftRadius={half === 'top' || half === 'mid' ? 0 : 4}

                />
            </Pane>
        </>
    )
}

export default TripleTextInput
