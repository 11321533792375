import React, { useState, useEffect } from 'react'
import styled from 'styled-components/macro'
import * as Types from '../types'
import { Pane, Heading, toaster, Label } from 'evergreen-ui'
import Card from '../card'
import CardHeader from '../card-header'
import PayButton from '../pay-button'
import { UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD, MEMBER_BILLING_HISTORY, UPDATE_CONTACT_SUBSCRIPTION, CREATE_PLAN_PAYMENT_EVENT } 
            from '../graphql/_view-member-sheet'
import { useMutation, useQuery } from '@apollo/react-hooks'
import { PaymentHistoryTable } from '..'
import moment from 'moment'
import { Switch as EvergreenSwitch } from 'evergreen-ui'
import ConfirmDialog from '../_dialogs/confirm-dialog'
import { Formik, Form } from 'formik'
import Button from '../button'
import AddPayment from '../_sheets/add-paymentsheet'
import { paymentStatus } from '../types'
import { memberAffectedQueries } from '../utility/member-affected-queries'

export type Props = {
    contact: Types.ViewMemberSheet_contact,
    isAdmin?: boolean,
    accountToken?: any,
    tanentType?: string
}


const ViewMemberBilling = ({ contact, isAdmin, accountToken, tanentType }: Props) => {
    const paymentKey = contact?.account?.clearentPaymentKey;
    const [isConfirmDialogCollectionMethodShown, setIsConfirmDialogCollectionMethodShown] = useState(false)
    const [confirmFunction, setConfirmFunction] = useState({} as any)
    const [cancelFunction, setCancelFunction] = useState({} as any)
    const currentSub = (contact.subscriptions && contact.subscriptions.length > 0) ? contact.subscriptions[0] : null
    // For saved credit card action
    const [updatePlanSubscriptionCard, updatePlanSubscriptionCardStatus] = useMutation<
        Types.UpdateContactPlanSubscriptionCard,
        Types.UpdateContactPlanSubscriptionCardVariables
    >(UPDATE_CONTACT_PLAN_SUBSCRIPTION_CARD,
        {
            refetchQueries: ['MembersTable', 'MembersSearch', 'ViewMemberSheet']
        })

    const [creditCardToken, setCreditCardToken] = useState<Types.SavedTokenInput | undefined>({
        cardType: currentSub?.creditCard_type ?? '',
        tokenId: currentSub?.creditCard_token ?? '',
        lastFourDigits: currentSub?.creditCard_lastfour ?? '',
        expDate: currentSub?.creditCard_exp ?? ''
    })

    const [updateSubscription] = useMutation<
        Types.UpdateContactSubscription,
        Types.UpdateContactSubscriptionVariables
        >(UPDATE_CONTACT_SUBSCRIPTION,
        {
            // refetchQueries: memberAffectedQueries
        })

    const [createPlanPaymentEvent, createPlanPaymentEventStatus] = useMutation<
        Types.CreateContactPaymentEvent,
        Types.CreateContactPaymentEventVariables
        >(CREATE_PLAN_PAYMENT_EVENT,
        {
            refetchQueries: [...memberAffectedQueries, 'memberBillingHistory']
        })
       

    const {
        data: billingHistoryData
    } = useQuery<Types.memberBillingHistory>(MEMBER_BILLING_HISTORY, {
        variables: {
            contactId: contact.id
        }
    })

    const updateToken = (token: Types.SavedTokenInput) => {
        // Call backend to update card info.
        if (currentSub != null) {
            updatePlanSubscriptionCard({
                variables: {
                    id: currentSub.id,
                    creditCard_token: token.tokenId,
                    creditCard_type: token.cardType,
                    creditCard_lastfour: token.lastFourDigits,
                    creditCard_desc: '',
                    creditCard_exp: token.expDate
                }
            })
            setCreditCardToken(token)
        }
    }

    useEffect(() => {
        if (updatePlanSubscriptionCardStatus.error) toaster.danger('Unable to update plan with credit card')
        else if (updatePlanSubscriptionCardStatus.data && !updatePlanSubscriptionCardStatus.loading) {
            toaster.success(`Credit card updated!`)
        }
    }, [updatePlanSubscriptionCardStatus])

    useEffect(() => {
        if (createPlanPaymentEventStatus.error) toaster.danger('Unable to save payment')
        else if (createPlanPaymentEventStatus.data && !createPlanPaymentEventStatus.loading) {
            toaster.success(`Payment saved!`)
        }
    }, [createPlanPaymentEventStatus])


    let charges: {
        createdAt: string,
        paymentDate: string | null,
        invoiceDate: string | null,
        amount: number,
        last4: string,
        cardExp: string | null,
        plan: string,
        status: string,
        isDependent: boolean,
        id: string, 
        invoiceNumber: string | null,
        paymentErrorMessage: string | null
        subscriptionId: string | null
    }[] = []

    billingHistoryData?.memberBillingHistory?.map(hist => {
        charges.push({
            createdAt: String(hist.createdAt) ?? moment.now,
            paymentDate: hist.paymentDate?moment(hist.paymentDate)?.format('M/D/YYYY'): null,
            invoiceDate: hist?.invoiceDate ? moment(hist.invoiceDate)?.format('M/D/YYYY'): null,
            amount: Number(hist.amount),
            last4: hist.last4 ?? '',
            cardExp: hist.cardExp,
            plan: hist.plan ?? '',
            status: hist.status ?? '',
            isDependent: hist.isDependent ?? false,
            id: hist.id ?? '',
            invoiceNumber: hist.invoiceNumber,
            paymentErrorMessage: hist.paymentErrorMessage,
            subscriptionId: hist.subscriptionId
        })
    })
    const [isAddPaymentSheetShown, setIsAddPaymentSheetShown] = useState(false)

    const savePayment = (payment: any) => {

        
        if(currentSub) {
            createPlanPaymentEvent({variables: {
                contactId: contact.id,
                subscriptionId: currentSub?.id,         
                paymentAmount: parseFloat(payment.amount),
                type: payment.type,
                paymentStatus: paymentStatus.COMPLETE,
                paymentDate: `${payment.payYear}-${payment.payMonth}-${payment.payDay}`,
                paymentNote: payment.note
            }});
        }
        setIsAddPaymentSheetShown(false);
    }
    const isEmpty=(s:string|null|undefined): boolean => {return (s ==null || s==undefined || s.length==0) }
    let hostUrl = "";
    if(process.env.GATSBY_APP_URL_HOST){
        hostUrl = process.env.GATSBY_APP_URL_HOST ?? ''
    }
    if(process.env.REACT_APP_URL_HOST){
        hostUrl = process.env.REACT_APP_URL_HOST ?? ''
    }
    return (
        <SheetBodyLayout>
            {isAdmin?
                <AddPayment
                    isShown={isAddPaymentSheetShown}
                    setIsShown={setIsAddPaymentSheetShown}
                    addPayment={savePayment}
                />:<></>
            }

            <ConfirmDialog
                isShown={isConfirmDialogCollectionMethodShown}
                setIsShown={setIsConfirmDialogCollectionMethodShown}
                confirm={confirmFunction}
                cancel={cancelFunction}
                body="Are you sure you want to change collection method"
                intent="danger"
            />
            <Pane gridArea="body" background="blueTint" overflow="scroll">

                {isAdmin ?
                    <Card backgroundColor="white" elevation={0} padding={0} margin={16} >
                        <CardHeader justifyContent="space-between">
                            <Heading size={500}>Collection Method</Heading>
                        </CardHeader>

                        <Pane display="flex" padding={16} justifyContent="flex-start">
                            <Formik initialValues={{
                                isManual: currentSub?.collectingMethod == Types.collectingMethod.MANUAL
                            }}
                                onSubmit={fields => {
                                    console.log(fields)
                                }}
                            >
                                {({ values, setFieldValue }) => (
                                    <Form>
                                        <Label alignSelf="flex-start" marginRight={8}>
                                            Collect payment offline/off PFH portal
                                        </Label>
                                        <EvergreenSwitch name="isManual"
                                            checked={values.isManual}
                                            onChange={() => {
                                                if(values.isManual && isEmpty(creditCardToken?.tokenId)) {
                                                    toaster.danger("Credit card is missing.")
                                                    return
                                                } 

                                                setConfirmFunction(() => () => {
                                                    const newValue = !values.isManual
                                                    setFieldValue("isManual", newValue)
                                                    updateSubscription({ variables: { id: currentSub!.id, isManual: newValue } })
                                                })
                                                setCancelFunction(() => () => {
                                                    console.log(values.isManual);
                                                })
                                                setIsConfirmDialogCollectionMethodShown(true)
                                            }}
                                            height={24} marginBottom={0} />
                                    </Form>
                                )}
                            </Formik>
                        </Pane>
                    </Card>
                    :

                    <></>}


                <Card backgroundColor="white" elevation={0} padding={0} margin={16} overflow="hidden" marginBottom={16}>
                    <CardHeader justifyContent="space-between">
                        <Heading size={500}>Setup Payment</Heading>
                    </CardHeader>
                    <PayButton id="view-member-plan-pay" paymentKey={paymentKey ?? ''} callBack={updateToken}
                        creditCardToken={creditCardToken} />
                </Card>
                <Card gridArea="history" elevation={0} padding={0} margin={16} overflow="hidden" marginBottom={16}>
                    <CardHeader>
                        <Heading size={500}>Billing History</Heading>

                        {isAdmin?
                            <Button
                                marginLeft={174}
                                onClick={() => setIsAddPaymentSheetShown(true)}
                            >
                                Add offline payment details
                            </Button>: <></>}

                    </CardHeader>
                    <Pane>
                        <PaymentHistoryTable tanentType={tanentType ?? ''} accountToken={accountToken} hostUrl={hostUrl}  charges={charges} isAdmin={isAdmin}/>
                    </Pane>
                </Card>

            </Pane>
        </SheetBodyLayout>
    )
}

export default ViewMemberBilling

const SheetBodyLayout = styled.div`
  height: 100%;
  overflow-x: auto;
  grid-template-rows: auto;

  grid-template-areas: 'body';
`
