import React from 'react'
import styled from 'styled-components/macro'

import { Pane, Text, Heading } from 'evergreen-ui'
import Card from '../card'
import CardHeader from '../card-header'

import moment from 'moment'

import PayButton from '../pay-button'

export type Props = {
  errorInfo: any
}

const ViewPaymentsProblems = ({ errorInfo }: Props) => {

  const updateToken = () => {
    console.log('token')
  }

  return (
    <>
      <SheetBodyLayout>
        <Pane gridArea="body" background="blueTint" overflow="scroll">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader>
              <Heading size={500}>Services Details</Heading>
            </CardHeader>
            <Pane padding={16}>
                <Pane padding={8} display="flex" flexDirection="column">
                <Text size={500} marginBottom={8}>
                  <strong>Start Date: </strong>
                  {moment.unix(errorInfo.createdAt).format('M/D/YYYY')}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>End Date: </strong>
                  
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Fee </strong>
                  {errorInfo.grossAmount}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Status: </strong> 
                  {errorInfo.status}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Auto Renew: </strong> 

                </Text>
              </Pane>
            </Pane>
          </Card>
          <Card backgroundColor="white" elevation={0} padding={0} margin={16} overflow="hidden" marginBottom={16}>
            <CardHeader justifyContent="space-between">
              <Heading size={500}>Billing Details</Heading>
            </CardHeader>
            <PayButton id="view-member-plan-pay" paymentKey={'aiydgaiyd'} callBack={updateToken} />
          </Card>
        </Pane>
      </SheetBodyLayout>

    </>
  )
}

export default ViewPaymentsProblems

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`