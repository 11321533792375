import React, { useState } from 'react'
import styled from 'styled-components/macro'

import { useQuery } from '@apollo/react-hooks'

import * as Types from '../types'

import { GET_CONTACT_PLAN_SUBSCRIPTION } from '../graphql/_member-payment-fields'

import moment from 'moment'

import { Pane, Text, Heading, toaster } from 'evergreen-ui'
import Button from '../button'
import Card from '../card'
import CardHeader from '../card-header'

import ConfirmDialog from '../_dialogs/confirm-dialog'
import ConfirmDialogPayment from '../_dialogs/confirm-dialog'
import ConfirmDialogSolved from '../_dialogs/confirm-dialog'

export type Props = {
  errorInfo: any
  confirmSolvedFunction: (errorInfo: any) => void
  tryNewPaymentFunction: (idConPlanSub: string) => void
}

const ViewPracticeProblems = ({ errorInfo, confirmSolvedFunction, tryNewPaymentFunction }: Props) => {

  const [isConfirmDialogShown, setIsConfirmDialogShown] = useState(false)

  const [isConfirmDialogPaymentShown, setIsConfirmDialogPaymentShown] = useState(false)

  const [isConfirmDialogSolvedShown, setIsConfirmDialogSolvedShown] = useState(false)


  const { data } = useQuery<Types.GetContactPlanSubscriptions, Types.GetContactPlanSubscriptionsVariables>(GET_CONTACT_PLAN_SUBSCRIPTION, {
    variables: {
      id: errorInfo.subscriptionId
    },
    skip: !errorInfo.subscriptionId
  })


  if (errorInfo.creditCard_lastfour === data?.getContactPlanSubscriptions?.creditCard_lastfour) {
    var paymentMsg = "Are you sure you want to try a new payment using the same credit card?"
  } else {
    paymentMsg = "Are you sure you want to try a new payment using the new credit card?"
  }


  const confirmFunction = () => {
    toaster.success('Email sent successfully!')
  }

  // const confirmPaymentFunction = () => {
  //   toaster.success('Payment finished successfully!')
  // }

  // const confirmSolvedFunction = () => {
  //   toaster.success('Problem deleted successfully!')
  // }


  return (
    <>
      <ConfirmDialog
        isShown={isConfirmDialogShown}
        setIsShown={setIsConfirmDialogShown}
        confirm={confirmFunction}
        body="Are you sure you want to send an email?"
        intent="danger"
      />
      <ConfirmDialogPayment
        isShown={isConfirmDialogPaymentShown}
        setIsShown={setIsConfirmDialogPaymentShown}
        confirm={() => tryNewPaymentFunction(errorInfo)}
        body={paymentMsg}
        intent="danger"
      />
      <ConfirmDialogSolved
        isShown={isConfirmDialogSolvedShown}
        setIsShown={setIsConfirmDialogSolvedShown}
        confirm={() => confirmSolvedFunction(errorInfo)}
        body="Are you sure you want to cancel this payment? Once you confirm the payment will be cancelled."
        intent="danger"
      />
      <SheetBodyLayout>
        <Pane gridArea="body" background="blueTint" overflow="scroll">
          <Card backgroundColor="white" elevation={0} margin={16} padding={0}>
            <CardHeader>
              <Heading size={500}>Error Details</Heading>
            </CardHeader>
            <Pane padding={16}>
              <Pane padding={8} display="flex" flexDirection="column">
                <Text size={500} marginBottom={8}>
                  <strong>Date: </strong>
                  {moment.utc(errorInfo.paymentDate).format('M/D/YYYY')}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Last Four Digits: </strong>
                  {errorInfo.creditCard_lastfour}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Exp. Date </strong>
                  {errorInfo.creditCard_exp}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Type: </strong>
                  {errorInfo.creditCard_type}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>{`Amout(USD): `}</strong>
                  {errorInfo.paymentAmount}
                </Text>
                <Text size={500} marginBottom={8}>
                  <strong>Message: </strong>
                  {errorInfo.creditCard_message}
                </Text>
              </Pane>
              <Pane display="flex" justifyContent="flex-start">
                {/*<Button
                  marginRight={8}
                  onClick={() => setIsConfirmDialogShown(true)}
                >
                  Send email
                </Button> */}
                <Button marginRight={8}
                  //isLoading={updateSubscriptionStatus.loading}
                  intent="danger"
                  onClick={() => setIsConfirmDialogPaymentShown(true)}
                >
                  Try new payment
                </Button>
                <Button marginRight={8}
                  intent="success"
                  onClick={() => setIsConfirmDialogSolvedShown(true)}
                >
                  Cancel Payment
                </Button>
              </Pane>
            </Pane>
          </Card>
        </Pane>
      </SheetBodyLayout>

    </>
  )
}

export default ViewPracticeProblems

const SheetBodyLayout = styled.div`
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;

  grid-template-areas:
    'body'
    'footer';
`