import React from 'react'

import { Tooltip, PositionTypes } from 'evergreen-ui'
import Icon, { Props as IconProps } from './icon'

import { colors } from './utility/theme'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

export interface Props extends Omit<IconProps, 'icon'> {
  content: string
  icon?: IconProp
  position?: PositionTypes
}

const InfoTooltip = ({
  content,
  icon = ['fas', 'info-circle'],
  color = colors.neutral.base,
  size = undefined,
  position = 'bottom',
  ...props
}: Props) => (
  <Tooltip content={content} position={position}>
    <Icon icon={icon} color={color} marginLeft={8} size={size} {...props} />
  </Tooltip>
)

export default InfoTooltip
